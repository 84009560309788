@import '../color-variables.scss';
@import '../mixins.scss';

.billing-page {
  .heading-title {
    @include heading-4;
    color: $title;
    margin-bottom: 26px;

    @include tablet {
      @include heading-6;
    }
  }

  .box-change-plan {
    margin-top: 20px;
    padding: 40px 40px 20px 40px;

    @include tablet {
      padding: 20px;
    }

    &__title {
      @include heading-6;
      color: $title;

      @include tablet {
        @include heading-8;
      }
    }
  }

  .list-table-wrap {
    padding: 0;
    margin-bottom: 20px;

    &__title {
      margin: 30px 0 10px 40px;
      @include text-medium-bold;
      color: $title;
    }

    &__content {
      margin: 0 40px;
    }

    .box-title {
      justify-content: space-between;
      align-items: center;

      @include tablet {
        flex-direction: column;
        align-items: flex-start;
      }

      .box-btn-cancel {
        margin-right: 40px;

        @include tablet {
          margin-left: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .text-title {
      @include text-medium-bold;
      color: $title;
      padding: 30px 40px 22px 40px;
      margin-bottom: 10px;

      @include tablet {
        @include heading-8;
        padding: 20px 20px 18px 20px;
      }
    }

    .table-plan {
      td {
        color: $paragraph;

        .name-plan {
          @include text-medium-bold;
          color: $title;
          text-transform: capitalize;
        }
      }
    }

    .tr-list {
      svg {
        color: $line;
      }

      &.active {
        svg {
          color: $green;
        }

        td {
          color: $title;
        }
      }

      .box-current {
        @include text-tiny-regular;
        color: $paragraph2;
        padding: 4px 10px;
        background: $tag;
        border-radius: 14px;
      }
    }
  }

  .current-plan {

    .list-table-wrap__content > div {
      width: calc(100%/6);
    }

    &--lowest-plan, &--no-upgrade {
      .list-table-wrap__content > div {
        width: 20%;
      }
    }

    .name-plan {
      color: $main;
      @include heading-6;
      text-transform: capitalize;
    }

    .detail {
      @include text-medium-regular;
      
      &__title {
        color: $paragraph;
        margin-bottom: 18px;
        
        & span {
          color: $paragraph;
        }
      }

      &__content {
        color: $paragraph1;
      }
    }

    &__button {
      text-align: right;
    }
  }

  .plan-notification {
    margin-bottom: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);
    border-radius: 18px;
    padding: 20px 40px;
  
    &--notification {
      background-color: #E5EEFF;
    }

    &--warning {
      background-color: #FCE7E7;    
    }

    &__button {
      @include text-medium-medium;

      &--notification {
        color: #1979FF;
      }

      &--warning {
        color: #E02323;
      }
    }
  }

  .billings {

    &__table {
      .header-list {
        th {
          @include text-medium-regular;
          text-transform: capitalize;
        }
      }

      .tr-list {
        td {
          @include text-medium-regular;
          border-bottom: none;
        }
      }
    }
  }

  .all-plans {
    padding: 25px;

    .list-table-wrap__content {
      gap: 25px;
    }

    &__plan-container {
      width: 25%;
      align-self: flex-end;
    }

    &__yearly-select {
      width: 100%;
      height: 41px;
      background-color: #E5EEFF;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      border: 1px solid #D9D9D9;
      border-bottom: none;
      padding: 0 24px;
      @include text-small-regular;
      text-decoration-color: $paragraph;
    }

    &__plan {
      width: 100%;
      height: 376px;
      border-radius: 14px;
      padding: 24px;
      border: 1px solid #D9D9D9;

      &--yearly {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
      }

      &__title {
        @include text-medium-bold;
        margin-bottom: 30px;

        &__price {
          @include heading-8;
          color: $main;

          .month-text {
            @include text-medium-regular;
            color: $paragraph;
          }
        }
      }

      &__descriptions {
        &:not(&:last-child) {
          margin-bottom: 10px;
        }

        &__info {
          @include text-medium-regular;
          margin-left: 10px;
          line-height: 22px;

          &--cu {
            @include text-medium-bold;
          }
        }
      }

      &__current-plan {
        @include text-medium-bold;
        line-height: 48px;
        color: $main;
        text-align: center;
        margin-top: auto;
      }

      &__button {
        margin-top: auto;
        width: fit-content;
        align-self: center;
      }
    }
  }

  .payment-method {
    cursor: pointer;
    padding: 0 40px;
    width: max-content;
    align-items: center;

    &:nth-child(2) {
      margin-bottom: 24px;
    }

    @include tablet {
      padding: 0 20px;
      justify-content: space-between;
      width: 100%;

      &:nth-child(2) {
        margin-bottom: 12px;
      }
    }

    &__value {
      color: $paragraph;
    }

    .btn-edit {
      color: $paragraph1;
      margin-left: 50px;
    }
  }

  .plan-card {
    .info {
      margin-bottom: 10px;
    }

    .plan-detail {
      @include text-small-regular;
      color: $paragraph;
      margin-left: 10px;

      &.price {
        margin-left: 35px;
      }
    }

    &.active {
      .plan-detail {
        svg {
          color: $green;
        }
      }
    }
  }

  .box-payment-method {
    padding: 0 0 32px 0;

    .text-title {
      @include heading-6;
      color: $title;
      padding: 30px 40px 22px 40px;

      @include tablet {
        @include heading-8;
        padding: 20px 20px 18px 20px;
      }
    }
  }

  .form-card {
    &__methods {
      padding: 30px 40px;
      background-color: $white;
      border-radius: 18px;
      box-shadow: 0px 15px 30px 0px $blackAlpha-50;
    }

    .sub-title {
      @include heading-5;
      color: $title;

      @include tablet {
        @include heading-7;
        position: absolute;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translateX(-50%);
      }
    }

    .icon-arrow-left {
      cursor: pointer;
    }

    .box-form-crypto {
      padding: 30px 40px 14px 40px;

      @include tablet {
        padding: 20px;
      }
    }

    .box-connect-wallet {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__description {
        @include text-large-regular;
        color: $paragraph;
        max-width: 400px;
        text-align: center;
        margin: 28px 0 40px 0;

        @include tablet {
          @include text-medium-regular;
          margin: 24px 0 40px 0;
        }
      }

      svg {
        @include tablet {
          width: 130px;
          height: auto;
        }
      }

      button {
        width: max-content;
      }
    }

    .top-up-input {
      position: relative;

      &__input {
        padding-right: 250px;

        @include tablet {
          padding-right: 200px;
        }
      }
      
      &__amount-options {
        position: absolute;
        right: 16px;
        top: 7px;

        @include tablet {
          margin-top: 0;
        }
  
        &__item {
          @include text-medium-regular;
          padding: 4px 16px;
          background-color: $bg;
          border-radius: 6px;
          border: 1px solid $line;
          color: $bg1;
          cursor: pointer;
          height: max-content;
  
          &:hover {
            border: 1px solid $main;
          }
  
          &:not(&:first-child) {
            margin-left: 10px;
          }
  
          @include tablet {
            @include text-small-regular;
            padding: 4px 8px;
  
            &:first-child {
              margin-left: 0;
            }
          }
  
          &--active {
            background: $main;
            border: 1px solid $main;
            color: $white;
          }
        }
      }
    }
  }

  .billing-checkout {
    &__bill {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 575px;
      margin: 0 auto;

      @include tablet {
        max-width: 343px;
      }
    }

    &__bill-info {
      background-image: url('../../assets/icons/bg-billing.png');
      padding: 40px 45px;
      background-repeat: no-repeat;
      width: 100%;

      @include tablet {
        padding: 34px;
        background-image: url('../../assets/icons/bg-billing-mobile.png');
      }
    }

    &__payment-method {
      padding-bottom: 30px;
      border-bottom: 1px dashed rgba(0, 2, 36, 0.1);

      .title {
        @include text-medium-bold;
        color: $line;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .type {
        @include text-medium-regular;
        color: $paragraph;
      }
    }

    &__order {
      height: 220px;
    }

    &__payment {
      margin-top: 20px;
    }

    &__order, &__payment {

      @include tablet {
        margin-bottom: 60px;
      }

      .title {
        @include text-medium-bold;
        color: $line;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .name-plan {
        @include text-large-bold;
        text-align: center;
        margin-bottom: 30px;

        .period {
          @include text-medium-regular;
          color: $paragraph;
        }
      }

      .plan-action {
        @include text-medium-regular;
        color: $paragraph;
      }

      .info {
        @include text-medium-regular;
        align-items: center;
        margin-top: 10px;

        svg {
          color: $green;
          margin-right: 12px;
        }
      }

      &__methods {
        .chakra-radio {
          height: 50px;
          border-radius: 6px;
          margin: 0;

          &__control {
            width: 20px;
            height: 20px;
          }

          &[data-checked] {
            background-color: #E5EEFF;

            .chakra-radio__control {
              background-color: $white;
              &::before {
                background-color: $main;
              }
            }
          }

          &[data-disabled] {
            .chakra-radio__control {
              border-color: $line;
            }
          }
        }

        .chakra-radio__label {
          width: 100%;
        }

        .chakra-radio {
          padding: 10px 18px;
        }

        .connect-wallet-btn {
          @include text-medium-medium;
          color: $main;
        }
      }

      &__selects {
        padding: 18px 0;
        margin: 0;
        
        &__field-text {
          @include text-medium-regular;
          color: $paragraph;
        }
        
        .app-select__btn-select {
          min-width: 165px;
        }
      }
    }
  }
}

.box-method {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 44px;
  border: 1.6px solid $line;
  border-radius: 18px;
  cursor: pointer;
  width: 49%;
  position: relative;

  @include tablet {
    width: 100%;
    padding-bottom: 30px;

    &:first-child {
      margin-bottom: 20px;
    }

    svg {
      max-width: 270px;
    }
  }

  &.active {
    border: 1.6px solid $blue;
  }

  &__btn-edit {
    color: $paragraph1;
  }

  &__name,
  &__method {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 2px;

    @include tablet {
      font-size: 16px;
    }
  }

  &__method {
    margin-bottom: 22px;
  }

  &__value {
    @include text-large-regular;
    color: $paragraph;
    padding-bottom: 18px;
  }

  .icon-checked-active {
    svg {
      width: 26px;
      height: 26px;
    }

  }
  
  .is-reloading {
    animation: spin 1s infinite linear;
  }
}

.box-top-up {
  border-radius: 18px;
  width: 49%;

  @include tablet {
    width: 100%;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  &>div {
    padding: 30px 36px;

    @include tablet {
      padding: 20px;
    }
  }

  &__label {
    @include text-medium-bold;
    color: $line;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  &__name {
    @include text-medium-bold;
    color: $paragraph;
  }
}