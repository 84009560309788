@import '../color-variables.scss';
@import '../mixins.scss';

.app-pagination {
  list-style-type: none;
  display: flex;
  margin: 25px 40px 0;

  @include tablet {
    margin: 15px 20px 10px 20px;
  }

  li {
    @include text-small-regular;
    border: 1px solid $line;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: rgba(0, 2, 36, 0.8);

    a {
      min-width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include text-small-regular;
      @media (max-width: 992px) {
        min-width: 24px;
        height: 24px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    &.selected {
      background: $gradient;
      color: #fff;
    }

    &:hover {
      background: $gradient;
      color: #fff;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      a {
        cursor: not-allowed;
      }

      &:hover {
        background: inherit;
        color: $title;
      }
    }
  }
}
