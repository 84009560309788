@import '../color-variables.scss';
@import '../mixins.scss';

.select-app {
  &__control {
    @include text-medium-regular;
    background-color: $bg2 !important;
    border-color: $line !important;
    height: 48px;
    border-radius: 6px !important;
    cursor: pointer;

    &:focus {
      border-color: $main;
    }

    .label-option {
      color: $title;
    }
  }
}

.app-select {
  position: relative;
  // z-index: 2;

  svg {
    color: $paragraph2;
  }

  &__btn-select {
    @include text-medium-regular;
    border: 1px solid $line;
    align-items: center;
    border-radius: 6px;
    z-index: 2;
    background: #fff;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid $line;

    .icon {
      margin-right: 8px;
    }

    &--disabled {
      background-color: $disable;
      cursor: not-allowed;
    }
  }

  &.small {
    .app-select__btn-select {
      height: 32px;
      padding: 0 16px;
    }
  }

  &.medium {
    .app-select__btn-select {
      height: 40px;
      padding: 0 20px;
    }
  }

  &.large {
    .app-select__btn-select {
      height: 48px;
      padding: 0 24px;
    }
  }

  &__menu {
    box-shadow: 0px 10px 40px rgba(125, 143, 179, 0.2);
    position: absolute;
    background: #fff;
    border-radius: 0 0 8px 8px;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 10px;
    top: 10px;
    z-index: 3;
    max-height: 200px;
    overflow-y: auto;
  }

  .app-select__menu {
    top: 100%;
  }

  &__menu-item {
    @include text-medium-regular;
    cursor: pointer;
    padding: 8px 18px;
    align-items: center;
    font-size: 14px;
    background-color: #fff;
    color: $title;

    &:hover,
    &.selected {
      background: $gradient;
      color: #fff;

      svg {
        color: #fff;
      }
    }

    .icon {
      margin-right: 10px;
    }
  }
}
