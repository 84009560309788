@import '../color-variables';
@import '../variables';
@import '../mixins.scss';

.workspace-page {
  display: flex;
  gap: 10px;
  margin-top: -40px;

  @media (max-width: 992px) {
    margin-top: 0;
    gap: 0;
  }

  .ref-line {
    position: relative;
    text {
      tspan {
        font-size: 12px;
        fill: $paragraph;
      }
    }
  }

  &__query {
    height: 100vh;
  }

  &__dashboard-full {
    width: 100%;

    .dashboard-container {
      background-color: transparent;

      .main-grid-layout {
        border: 0;
        height: 100% !important;
        max-height: 100%;
        overflow-y: inherit;

        .box-layout {
          border: 0;
          border-radius: 18px;
          .box-chart {
            padding: 20px;
          }
        }
      }
    }
  }

  &__sidebar {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
    display: flex;
    height: 100%;

    ::-webkit-scrollbar {
      background-color: white;
    }
    &__categories {
      padding: 14px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      transition: all 0.2s ease;
    }

    &__categories.hidden-sidebar-content {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &__content {
      height: 100%;
      flex: 1;
      border-left: 1px solid rgba(0, 2, 36, 0.1);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;

      .data-empty {
        color: $paragraph;
        font-size: 14px;
        display: flex;
        justify-content: center;
      }

      &.hidden-sidebar {
        overflow: hidden;
        animation: hiddenSidebar 0.5s forwards;
        opacity: 0;
      }

      &.show-sidebar {
        overflow: hidden;
        animation: showSidebar 0.5s forwards;
      }

      &__work-place-detail {
        @include text-small-regular;
        padding: 0px 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 28px;
        gap: 6px;
        transition: all 0.2s ease;
        position: relative;
        color: $paragraph1;

        .app-query-menu {
          display: none;
        }

        &:hover {
          .app-query-menu {
            display: block;
          }
        }
      }
      .work-place-active {
        p {
          &:hover {
            color: $title !important;
          }
        }
      }
      &__work-place-wrap {
        width: 240px;
        overflow-y: hidden;
        height: 100%;
        padding-bottom: 10px;
        &__work-place {
          padding: 16px 16px 8px 16px;
          display: flex;
          @include text-small-bold;
          text-transform: uppercase;
          align-items: center;
          justify-content: space-between;
        }
        &__work-place-content {
          @include text-small-bold;
          padding-left: 16px;
          padding-right: 16px;
          justify-content: space-between;
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          margin-top: 30px;
        }
        &__list-query {
          height: calc(100% - 162px);
          overflow-y: hidden;
          &:hover {
            overflow-y: auto;
          }
        }
        &__input-search {
          border: 1px solid $line !important;
          padding: 14px 14px 14px 28px !important;
        }
      }

      .chakra-input__left-element {
        top: 0px;
        left: 0px;
      }

      &__explore-wrap {
        height: 100%;
        position: relative;
        width: 240px;

        &__list-chain {
          height: calc(100% - 154px);
          overflow-y: hidden;
          &:hover {
            overflow-y: auto;
          }
        }

        &__list-chain-half {
          height: calc(100% - 480px);
          overflow-y: hidden;
          &:hover {
            overflow-y: auto;
          }
        }

        &__table-search {
          height: calc(100% - 154px);
          overflow-y: hidden;
          padding-bottom: 10px;
          .chain-info-title {
            @include text-small-regular;
            color: $paragraph1;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 6px 16px;
            transition: 0.2s all ease;

            .add-query-icon {
              opacity: 0;
              position: relative;
            }
            &:hover {
              background-color: #f4f6f9;
              .add-query-icon {
                opacity: 1;
                & > svg {
                  color: #00022480;
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
          &:hover {
            overflow-y: auto;
          }

          .title {
            padding: 6px 16px;
            color: $paragraph;
            font-size: 14px;
          }
        }

        &__content {
          @include text-small-regular;
          padding-left: 16px;
          padding-right: 16px;
          color: $paragraph;
          margin-bottom: 12px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: space-between;
          .bg-arrow_icon {
            transition: 0.2s all ease;
          }
          .bg-arrow_icon.collapsed {
            transform: rotate(90deg);
          }
        }
        .chain-info-desc {
          @include text-small-regular;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 40%;
          left: 0;
          color: $paragraph1;
          padding-bottom: 16px;

          &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 16px;

            .header-icon {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: end;
              .icon-header {
                cursor: pointer;
                color: white;
                width: 14px;
                height: 14px;
              }
            }
          }
          &__content {
            height: calc(100% - 28px);
            overflow-y: auto;

            .data-type {
              display: flex;
              flex-direction: row;
              align-items: center;

              & > svg {
                color: #00022480;
                width: 14px;
                height: 14px;
                margin-left: 12px;
              }
            }
          }
        }
        &__title {
          display: flex;
          padding: 16px 16px 8px 16px;
          @include text-small-bold;
          text-transform: uppercase;
          align-items: center;
          justify-content: space-between;
        }

        &__content-collapse {
          padding-bottom: 10px;
          .chain-info-title {
            @include text-small-regular;
            color: $paragraph1;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 6px 16px;
            transition: 0.2s all ease;

            .add-query-icon {
              opacity: 0;
              position: relative;
            }
            &:hover {
              background-color: #f4f6f9;
              .add-query-icon {
                opacity: 1;
                & > svg {
                  color: #00022480;
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  &__editor {
    width: 100%;

    &--query {
      width: calc(100% - 300px - 10px);
    }

    &--full {
      width: 100%;
    }

    &__tabs {
      margin-bottom: 12px;
      align-items: center;
      gap: 10px;
      max-width: 100%;
      overflow-x: auto;
      white-space: nowrap;

      &__tab {
        padding: 10px;
        align-items: center;
        background-color: $white;
        border-radius: 6px;
        cursor: pointer;

        span {
          margin-right: 10px;
        }

        &--active {
          background-color: rgba(229, 238, 255, 1);
          
          span {
            color: rgba(28, 95, 226, 1);
          }
        }
      }
    }

    .empty-dashboard {
      width: 100%;
      height: calc(100vh - 235px);
      column-gap: 12px;
      background-color: #ffffff;
      margin-top: 40px;
      border-radius: 6px;

      .add-widget {
        width: 300px;
        height: 160px;
        border: 1px solid $line;
        border-radius: 10px;
        background: $bg;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;

        &:hover {
          border: 1px solid $main;
          background: #ffffff;
          box-shadow: 0 10px 40px rgba(125, 143, 179, 0.2);
          color: $main;
        }

        @media (max-width: 992px) {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          width: 150px;
          padding: 10px 0;
          height: max-content;
          border-radius: 8px;
        }
      }
    }

    &__dashboard {
      max-width: 1400px;
      margin: 0 auto;
    }

    &__dashboard,
    &__query {
      height: 100%;

      .query-container {
        height: 100%;

        .empty-dashboard {
          width: 100%;
          height: calc(100% - 53px);
          column-gap: 12px;

          .add-widget {
            width: 200px;
            height: 100px;
            border: 1px solid #465065;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 10px;
            cursor: pointer;

            &:hover {
              border: 1px solid $blue;
              background: linear-gradient(268.85deg, rgba(34, 108, 255, 0.12) 22.48%, rgba(16, 132, 255, 0.12) 83.59%);
              box-shadow: 0px 10px 40px rgba(125, 143, 179, 0.2);
            }
          }
        }
      }

      .dashboard-container {
        .add-button {
          position: fixed;
          bottom: 22px;
          right: 22px;
          z-index: 1000;

          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          width: 56px;
          height: 56px;
          background: linear-gradient(268.85deg, #226cff 22.48%, #1084ff 83.59%);
          border-radius: 50%;

          &:hover {
            background: $blue;
          }
        }

        .app-query-menu__list {
          margin-bottom: 8px;
        }
      }
    }

    &--expand {
      width: calc(100% - 70px);
    }

    &__header {
      position: relative;
      z-index: 6;
      padding: 9px 20px 9px 10px;
      background: $card;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $disable;

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 14px;

        .icon-back-light {
          display: none;
        }
      }

      &__left {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        gap: 5px;

        .btn-back {
          &:hover {
            background-color: $bg1;
          }
        }

        .item-desc {
          display: flex;
          align-items: center;
          gap: 8px;
          width: calc(100% - 42px);
          overflow: hidden;

          .user-name {
            color: $paragraph;
          }

          &__name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
          }

          &__forked {
            font-size: 16px;
            font-style: italic;
            color: $paragraph2;
            max-width: 50%;
            display: flex;
            white-space: nowrap;
            flex-direction: row;

            .link-forked {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $main;
              margin-left: 4px;
            }
          }

          img {
            width: 26px;
            height: 26px;
          }
        }
      }

      &__right {
        display: flex;
        align-items: center;

        .btn-list {
          padding: 0;
          &:hover {
            background-color: $bg1;
          }
        }

        .switch-icon {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        .network-icons {
          margin-right: 24px;
        }

        .tag {
          margin-right: 10px;
        }
      }
    }

    .header-tab {
      height: 48px;
      padding: 12px 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        display: flex;
        align-items: center;

        .network-icons {
          margin-right: 24px;
        }
      }
      &__tag {
        display: flex;
        align-items: center;
      }

      .btn-expand {
        height: 100%;
        margin-left: auto;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .hidden-sidebar {
    animation: hiddenSidebar 0.5s forwards;
    opacity: 0;
  }

  .show-sidebar {
    animation: showSidebar 0.5s forwards;
  }
}

@keyframes hiddenSidebar {
  from {
    width: 240px;
  }
  to {
    width: 0;
    padding: 0;
  }
}

@keyframes showSidebar {
  0% {
    opacity: 0;
    width: 0px;
    padding: 0;
  }
  99% {
    opacity: 0.1;
    width: 240px;
  }
  100% {
    opacity: 1;
  }
}

.text-header {
  @include heading-7;
}
.item-filter {
  @include text-small-bold;
  text-transform: uppercase;
}
.m-drawer-filter {
  width: 100%;
  height: 100%;
  padding: 44px 16px;
  .drawer-header {
    align-items: center;
    justify-content: center;
    position: relative;
    height: 42px;
    margin-bottom: 32px;
    .icon-back-light {
      position: absolute;
      top: calc(50% - 19px);
      left: 0;
      width: 38px;
      height: 38px;
    }
    .btn-close-drawer {
      min-width: auto;
      position: absolute;
      top: calc(50% - 15px);
      right: 0;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid #c7d2e1;
      padding: 0;
    }
  }
}

@media (max-width: 992px) {
  .workspace-page__sidebar__content__explore-wrap,
  .workspace-page__sidebar__content__work-place-wrap {
    width: 100%;
    .workspace-page__sidebar__content__explore-wrap__title,
    .workspace-page__sidebar__content__work-place-wrap__work-place {
      justify-content: flex-start;
      padding-bottom: 24px;
      padding-top: 0;
      @include text-small-bold;
    }
    .workspace-page__sidebar__content__work-place-wrap__work-place-content {
      margin-top: 24px;
    }
  }
  .workspace-page__editor--query {
    width: 100%;
  }
  .workspace-page__editor__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .workspace-page__editor__header__left {
      flex-grow: 1;
      .item-desc {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        .item-desc__name {
          display: block;
          max-width: calc(100% - 34px);
        }
      }
    }
    .header-tab__info.tag {
      width: 100%;
      padding-top: 14px;
      margin-top: 14px;
      border-top: 1px solid rgba(0, 2, 36, 0.1);
    }
  }
  .side-content-explore {
    min-height: 100vh;
    width: 100%;
    z-index: 10;
    .overlay-side {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1401;
      background: #000;
      opacity: 0.6;
    }
    .content-side {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1402;
      max-height: 50vh;
      width: 100%;
      overflow: hidden;
      background: white;
      border-radius: 16px 16px 0px 0px;
      .close-bar {
        width: 48px;
        height: 5px;
        border-radius: 2.5px;
        background: #c7d2e1;
      }
      .body-side {
        width: 100%;
        height: 100%;
        padding-top: 14px;
      }
    }
  }
  .m-chain-info-desc {
    .chain-info-desc__header {
      background: #0002240d !important;
      padding: 10px 20px;
      margin-bottom: 14px;
      .info-detail-header {
        color: $title !important;
        @include text-medium-medium;
      }
      .header-icon .icon-header {
        color: #00022480 !important;
      }
    }
  }
}
