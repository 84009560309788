@import '../color-variables';
@import '../variables';
@import '../mixins.scss';

.dashboards-page {
  width: 100%;
  // padding: 20px;
  gap: 20px;

  @include mobile {
    padding: 20px 10px;
  }

  .dashboard-list {
    width: 100%;

    .chakra-tabs__tab {
      padding: 10px 0;
      border-bottom: 2px solid transparent;

      &[aria-selected='true'] {
        border-bottom: 2px solid #0060db;

        svg {
          color: #0060db;
        }
        .tab-name-content {
          color: $bg1;
        }
      }

      .app-tab__name-tab {
        margin-right: 20px;
      }

      .tab-name-content {
        color: rgba(0, 2, 36, 0.5);
        @include text-large-bold;
        @media (min-width: 992px) {
          @include heading-8;
        }
      }
      svg {
        color: rgba(0, 2, 36, 0.5);
      }
    }

    .tab-list {
      border-bottom: 1px solid #465065;
    }
    .block-table {
      display: block;
    }
  }

  .btn-active {
    border-color: #1878ff;
    color: #0060db;
    padding: 0 24px;
    background: linear-gradient(268.85deg, rgba(34, 108, 255, 0.12) 22.48%, rgba(16, 132, 255, 0.12) 83.59%);
    &:focus {
      box-shadow: unset;
      border: 1px solid #0060db;
    }
  }

  .btn-inactive {
    padding: 0 24px;
  }

  .dashboard-filter {
    width: 100%;
    margin-top: 30px;

    &__network {
      background: $title;
      border: 1px solid $line;
      border-radius: 50px;
    }

    &__search,
    &__tags {
      padding: 20px;
      // background-color: #101530;

      &__title {
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 14px;
      }

      &__select {
        height: 44px !important;

        & > .app-select__btn-select {
          width: 200px;
        }
      }

      &__search-box {
        background-color: $white;
        position: absolute;
        width: 100%;
        z-index: 100;
        border-end-start-radius: 5px;
        border-end-end-radius: 5px;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.04);

        &--item {
          cursor: pointer;
          padding: 7px 25px;
          font-weight: bold;

          &:hover {
            background-color: $bg;
          }
        }

        .no-result {
          padding: 7px 25px;
          color: $line;
        }
      }

      &__selects,
      &__input {
        height: 40px !important;
        border: 1px solid $line;
        padding: 18px 20px 18px 46px;

        &__item {
          padding: 5px 9px;
          margin-right: 6px;
          color: $line;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid #272d52;
            border-radius: 8px;
            color: $title;
          }

          &--selected {
            border: 1px solid #272d52;
            border-radius: 8px;
            color: $title;
          }
        }

        &--tag {
          @include text-medium-regular;
          @include flex-justify-start;
          align-items: center;
          border-radius: 6px;
          background-color: $white;
          padding: 8px 16px;

          .app-tag {
            background-color: $white;
            color: $paragraph1;
            border: 1px solid $line;
            border-radius: 6px;
          }
        }
      }

      &__selects--time {
        margin-bottom: 14px;

        &__item {
          padding: 5px 9px;
          margin-right: 6px;
          color: $line;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 6px;
          font-size: 14px;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid #272d52;
            border-radius: 8px;
            color: $title;
          }

          &--selected {
            border: 1px solid #272d52;
            border-radius: 8px;
            color: $title;
          }
        }
      }

      &__tag {
        padding: 8px 14px;
        margin-bottom: 20px;
        border: 1px solid #272d52;
        border-radius: 8px;

        .tag-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;

          svg {
            cursor: auto;
            margin-right: 10px;
            width: 16px;
            height: 16px;
          }
        }

        svg {
          cursor: pointer;
        }
      }

      .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 220px;
      }

      &__button {
        background-color: $title;
        border: 0.9375px solid $line;
        margin-left: 10px;
        color: $paragraph;

        &:hover {
          background-color: transparent;
          color: #1878ff;
          border: 1px solid #1878ff;
        }
        &--active {
          background-color: transparent;
          color: #1878ff;
          border: 1px solid #1878ff;
        }
      }
    }

    &__tags {
      margin-top: 20px;

      &__list {
        svg {
          margin-right: 10px;
          width: 18px;
          height: 18px;
        }

        &__item {
          padding: 8px 0;
          font-size: 14px;
          line-height: 21px;
          width: 100%;
          border-bottom: 1px solid #272d52;

          &:not(&:last-child) {
            border-bottom: 1px solid #272d52;
          }
        }

        &__tag {
          color: $line;

          &--selected {
            text-decoration: underline;
            color: $title;
          }
        }

        &__popularity {
          border: 1px solid #272d52;
          border-radius: 3px;
          padding: 5px;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    &--mobile {
      width: 100%;
      margin: 20px 0;
      display: none;

      @include desktop {
        display: block;
      }
    }
  }
}

// start:: new style by chau.le@sotatek.com
.article {
  max-width: 100%;
  overflow: hidden;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid $line;
    background-color: $bg3;

    .article-link .article-name {
      color: $main;
    }
  }

  .article-link {
    display: flex;
    flex-direction: row;
    align-items: center;

    .article-name {
      color: $bg1;
      text-transform: capitalize;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include text-medium-bold;
    }
    .article-tag {
      background: rgba(0, 2, 36, 0.05);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 2, 36, 0.5);
      @include text-small-regular;
    }
    .article-creator {
      color: $bg1;
      @include text-small-medium;
    }
    .article-date {
      color: rgba(0, 2, 36, 0.5);
      @include text-small-regular;
    }
  }

  .m-article-label {
    color: rgba(0, 2, 36, 0.5);
    @include text-small-regular;
    margin-right: 10px;
  }
  .m-article-creator,
  .m-article-date {
    color: $bg1;
    @include text-small-regular;
  }
  .article-row-creator {
    color: $bg1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include text-medium-regular;
  }
}

.dashboard-list {
  .table-header {
    text-transform: uppercase;
    color: $bg1;
    opacity: 0.3;
    padding: 0 26px;
    margin-bottom: 6px;
    @include text-display-bold-small;
  }
  .dashboard-filter {
    margin-top: 0px !important;

    .filter {
      align-items: center;
      padding: 0 20px;
      height: 40px;
      min-width: 124px;
      background: white;
      border: 1px solid #c7d2e1;
      border-radius: 6px;
      cursor: pointer;
    }

    .text-filter {
      color: $bg1;
      @include text-medium-medium;
    }

    .btn-create {
      background: linear-gradient(271.63deg, #1e72ff -2.92%, #0250be 102.1%);
      &:hover {
        background: linear-gradient(271.63deg, #0250be -2.92%, #1e72ff 102.1%);
      }
    }
  }
}

// 23/06
.m-article-row {
  .article-name {
    color: $title;
    text-transform: capitalize;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include text-medium-bold;
  }
  .article-row-creator {
    color: $title;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include text-small-regular;
  }
  .article-label {
    color: $title;
    opacity: 0.5;
    @include text-small-regular;
  }
  .article-date,
  .article-view {
    color: $title;
    @include text-small-regular;
  }
  .article-tag {
    background: rgba(0, 2, 36, 0.05);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 2, 36, 0.5);
    @include text-small-regular;
  }
}
.article-row {
  outline: transparent solid 1px;
  transition: all 0.15s linear;
  .article-name {
    color: $title;
    text-transform: capitalize;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include text-medium-bold;
  }
  .article-creator,
  .article-date,
  .article-view {
    color: $title;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include text-medium-regular;
  }
  .article-tag {
    background: rgba(0, 2, 36, 0.05);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 2, 36, 0.5);
    @include text-small-regular;
  }
  .article-dropdown {
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: rgba(0, 2, 36, 0.05);
  }
  &:hover {
    outline: #c7d2e1 solid 1px;
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.04);
  }
}
// end:: new style by chau.le@sotatek.com
