@import '../color-variables.scss';
@import '../mixins.scss';

.create-webhook-container {
  .create-webhook {
    &__detail-info {
      padding: 30px 40px !important;

      .select-module {
        .app-select__menu {
          z-index: 10;
        }
      }

      .frame-address {
        border-radius: 6px;
        border: 1px solid #d5dde9;
        padding: 5px 3px 7px 3px;

        input {
          padding: 0px !important;
          border: unset !important;
          height: unset !important;
        }

        .btn-delete-address {
          border-radius: 6px;
          background: linear-gradient(180deg, #1979ff 0%, #0756c9 63.54%);
          padding: 6px 16px;
          color: $white;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
          margin-left: 10px;

          &:hover {
            background: linear-gradient(180deg, #408cf8 0%, #2069d7 63.54%);
          }
        }

        .number-index {
          text-align: right;
          color: #8d91a5;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          min-width: 19px;
          letter-spacing: 0.1px;
        }

        .input-address {
          padding: 5px 15px;
        }

        .line-address {
          padding: 5px 15px;

          p {
            letter-spacing: 0.02px !important;
          }

          input {
            letter-spacing: 0.1px !important;
          }

          &:hover {
            background: #f4f6f9;
          }

          .invalid-card {
            border-radius: 6px;
            background: #e02323;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: $white;
            padding: 0px 4px;
          }

          .text-address-error {
            color: #e02323;
          }
        }
      }
      @include tablet {
        padding: 20px !important;
      }
    }
    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $paragraph1;
    }
    &__header {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      position: relative;
    }
    &__identification {
      padding: 30px 40px 40px 40px;
      .title {
        color: $paragraph2;
      }
      @include tablet {
        padding: 20px !important;
      }
    }

    &__url {
      padding: 30px 40px !important;
      @include tablet {
        padding: 20px !important;
      }

      .field {
        margin-bottom: 0;
      }
      .chakra-input__right-element {
        width: 206px;
        padding-right: 16px;
        @include tablet {
          display: none !important;
        }
      }
    }
    &__coin-aptos,
    &__token-aptos {
      .box-list-events {
        &__wrapper {
          margin-left: 0;
        }
        background-color: $bg;
        padding: 16px 24px;
        border-radius: 6px;
        border: 1px solid $line;
      }
      .token-activity {
        .field {
          .token-name {
            min-height: 48px !important;
            padding: 14px 20px 0;
          }
        }
      }
    }
  }
  .name {
    @include heading-4;
    align-items: center;
    margin-bottom: 3px;

    @include tablet {
      @include heading-6;
    }

    .title-mobile {
      @include tablet {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      }
    }
  }
  .select-type-webhook {
    .app-select__btn-select {
      z-index: 99;
    }
  }
}
