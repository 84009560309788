@import '../color-variables.scss';
@import '../mixins.scss';

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  z-index: 1100;

  .content-header {
    max-width: 1320px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 100%;
    color: white;
    font-weight: 500;
    font-size: 18px;

    .button {
      border-radius: 16px;
      font-size: 14px;
    }

    .menu-header {
      font-size: 16px;
      max-width: 200px;
      border: none;
      padding: 18px 20px;
      border-radius: 18px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .user-info {
        padding: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;

        display: block;
        text-align: left;
        font-weight: 700;

        &:focus {
          background-color: transparent;
        }

        .user-account {
          color: $paragraph;

          &:hover {
            color: $main;
          }

          &.active {
            color: $main;
            font-weight: 500;
          }
        }

        .user-email,
        .user-name {
          overflow: hidden;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 20px;
        }

        .user-email {
          font-size: 14px;
          color: $paragraph;
          font-weight: 400;
        }

        .user-divider {
          margin-top: 14px;
          width: 100%;
          border: 1px solid #2f3b58;
        }
      }
    }

    .signup-login {
      @include text-large-regular;
      color: $paragraph;

      &__link:hover {
        color: $title;
      }
    }
  }

  .menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);


    a {
      @include text-large-regular;
      display: block;
      margin-right: 40px;
      color: $paragraph1;

      &:hover {
        color: $main;

      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: $main;
        font-weight: 500;
      }
    }
  }

  .user-logout {
    color: $red;
    padding-top: 14px;
    font-weight: 600;
    font-family: 'DM Sans';
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include tablet {
      font-weight: 500;
      font-size: 18px;
    }

    span {
      &:last-child {
        transform: translateX(-5px);
      }

      &:first-child {
        transform: translateX(5px);
      }
    }

    .arrow-logout {
      transform: translateX(-70%);
      transition: all linear 0.5s;
    }

    &:hover {
      text-decoration: underline;

      .arrow-logout {
        transform: translateX(-50%);
      }
    }

    span {
      color: $red;
    }
  }

  .header-mobile {
    height: calc(100vh - 80px);
    background-color: $bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;

    .menu-mobile {
      flex-direction: column;
      height: 100%;
      align-items: center;
      padding-top: 80px;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;

      a {
        display: block;
        margin-bottom: 40px;
        color: $paragraph1;

        &:hover {
          color: $main;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          font-weight: 500;
          color: $main;
        }
      }
    }

    .btn-logout {
      color: $red;

      svg {
        margin-right: 10px;
      }
    }
  }

  .btn-close {
    border: 2px solid #41495f;
    border-radius: 48px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}