@import '../color-variables.scss';
@import '../mixins.scss';

.app-tab {
  &__name-tab {
    @include text-small-regular;

    color: $paragraph;
    height: max-content !important;
    font-size: 16px !important;
    box-shadow: none;
    margin-right: 14px;

    svg {
      margin-right: 10px;
    }

    &[aria-selected='true'] {
      @include text-small-bold;

      box-shadow: none !important;
      color: $title;
    }

    .tab-name-content {
      font-weight: 400;
      line-height: 24px;
    }

    &__detail {
      padding: 0 10px;
    }
  }

  &__content-tab {
    padding: 0 !important;
  }
}
