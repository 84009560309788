@import '../color-variables.scss';
@import '../mixins.scss';

.box-login {
  justify-content: center;
  min-height: calc(100vh - 200px);
  margin-top: 70px;
  align-items: center;

  .box-form {
    max-width: 570px;
    padding: 50px 67px;
    height: max-content;
    color: $paragraph;

    @include tablet {
      padding: 40px 20px;
      margin: 0 16px;
    }

    a {
      @include text-medium-medium;
      color: #0060db !important;
      font-weight: 500;
    }

    &__title {
      @include heading-4;
      color: $title;
      text-align: center;
      margin-bottom: 2px;

      @include tablet {
        @include heading-6;
      }
    }

    .sub-text {
      @include text-medium-regular;
      color: $paragraph1;
    }

    .btn-login-google,
    input {
      border: 1px solid $line;
    }

    .btn-login-google {
      font-weight: 400;

      &:disabled {
        background-color: $line;
        color: $title;
      }
    }

    .divider {
      @include text-medium-regular;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      margin-top: 10px;
      color: $paragraph1;

      .border {
        border: 1px solid $line;
        width: 45%;
      }
    }

    .note {
      @include text-medium-regular;
      color: $paragraph1;
    }

    .link-back {
      justify-content: center;
      margin-top: 20px;
    }

    button:disabled {
      background: $main;
      color: $white;
    }
  }
}