.base-page-container {
  min-height: calc(100vh - 200px);

  &__content {
    width: 100%;
    max-width: 1272px;
    padding: 20px 16px 0px 16px;

    &--full {
      max-width: initial;
      padding: 0;
    }
  }
}
