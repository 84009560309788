@import '../color-variables.scss';
@import '../mixins.scss';

.skeleton {
  height: 18px;
  margin-bottom: 6px;
  border-radius: 15px;
  background: $disable;
  background: linear-gradient(to right, #bfc2c9 8%, #e5e6e9 18%, #bfc2c9 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
