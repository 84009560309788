@import '../color-variables';
@import '../variables';
@import '../mixins.scss';

.app-query-menu {
  padding: 0 !important;

  > span {
    flex: initial;
  }

  &__list {
    border: unset;
    min-width: 109px;
    border-radius: 6px;
    border: none;
    min-width: 104px !important;

    .chakra-menu__menuitem {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $title;
      svg {
        width: 16px;
        opacity: 0.5;
      }
      &:hover,
      &:focus {
        background: #0060db !important;
        color: white !important;

        svg {
          color: white !important;
          opacity: unset !important;
        }
      }
    }
  }
}
