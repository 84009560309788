@import '../color-variables.scss';
@import '../mixins.scss';

.app-detail {
  .app-info {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
  }

  .name {
    @include heading-4;
    align-items: center;
    margin-bottom: 3px;

    @include tablet {
      @include heading-6;
    }

    .title-mobile {
      @include tablet {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      }
    }
  }

  .box-network {
    @include text-medium-regular;
    color: $title;
    padding: 8px 18px;
    background-color: $card;
    border-radius: 20px;
    margin-right: 12px;
    background: $white;
  }

  .setting-btn {
    color: $title;
  }

  .app-tabs {
    margin: 0 40px;
    border-bottom: 1px solid $disable;

    @include tablet {
      margin: 0 20px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;

      /* Firefox */
      &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }

      & > div {
        width: max-content;
      }
    }

    .app-tab {
      @include text-large-bold;
      padding: 0 0 8px 0;
      margin-right: 40px;
      color: $paragraph2;

      @include tablet {
        width: max-content;
        margin-right: 20px;
        @include text-medium-bold;
      }

      &[aria-selected='true'] {
        box-shadow: none;
        border-bottom: 2px solid $main;
        color: $main;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.no-tab {
      border-bottom: 0;
      color: $title;

      .app-tab {
        padding-bottom: 0;

        &[aria-selected='true'] {
          border-bottom: 0;
        }
      }
    }
  }

  .content-tab-app {
    padding: 0;
    margin-top: 10px;

    @include tablet {
      margin-top: 10px !important;
    }

    &.no-tab {
      margin-top: 0;
    }
  }

  .list-webhook {
    padding: 30px 0 0 0 !important;

    @include tablet {
      padding-top: 24px !important;
    }
  }

  .description {
    @include text-medium-regular;
    color: $paragraph1;
  }

  .list-nft {
    .description {
      margin-top: 0;
      font-size: 13px;
      text-transform: uppercase;
    }

    table th {
      background: #f9f9f9;
    }

    table tr {
      background-color: white;
    }
  }

  .box-create {
    @include text-large-regular;
    color: $title;
    max-width: 480px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    text-align: center;

    @include tablet {
      padding: 0 15px;
    }

    svg {
      margin-bottom: 20px;
    }

    button {
      margin-top: 20px;
    }
  }
}

.statics {
  margin-bottom: 20px;

  @include tablet {
    margin-bottom: 20px;
  }
}

.infos {
  .box-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $white;
    border-radius: 18px;
    padding: 28px 20px;
    border: 1px solid $white;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);

    &:hover {
      cursor: pointer;
      border: 1.1px solid $main;
    }

    .label {
      @include text-medium-regular;
      margin-bottom: 4px;
      color: $paragraph1;
    }

    .value {
      @include heading-6;
      color: $title;
    }
  }

  .chart {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  .chartMobile {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  .statsMobile {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .statsItemMobile {
      min-width: 80%;
      margin-right: 10px;
    }

    :last-child {
      margin-right: 0px;
    }
  }
}

.app-status {
  padding: 20px 40px !important;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.04);

  @include tablet {
    padding: 20px !important;
  }

  .box-status {
    align-items: center;
    flex-direction: row;

    @include tablet {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

.basic-setting {
  margin: 20px 0 20px 0;
  padding-top: 30px !important;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.04);

  @include tablet {
    padding: 20px !important;
    margin: 20px 0;
  }

  .chain-app {
    align-items: center;
    border-radius: 6px;
    background-color: rgba(0, 2, 36, 0.05);
    width: 100%;
    padding: 0 14px;
    height: 48px;
    color: $paragraph2;
  }

  .abi-detail {
    background-color: rgba(0, 2, 36, 0.05);
    border: 0;

    .box-events {
      background-color: $white;

      .abi-option .inputs {
        color: $paragraph1;
      }

      .chakra-checkbox__label {
        opacity: 1;
      }

      .box-item {
        &:first-child {
          padding-bottom: 16px;
        }
      }
    }
  }
}

.title-status {
  @include heading-6;
  line-height: 30px;
  margin-right: 50px;

  @include tablet {
    @include heading-8;
    margin-bottom: 14px;
    margin-right: 10px;
  }
}

.button {
  background: linear-gradient(271.63deg, #1e72ff -2.92%, #0250be 102.1%) !important;
}

.field-disabled {
  @include text-medium-regular;
  padding: 12px 16px;
  background-color: rgba(0, 2, 36, 0.05);
  border-radius: 6px;
  color: $paragraph;
}

.form-create {
  background-color: $card;

  @include tablet {
    padding: 20px !important;
  }

  .select-type-webhook {
    .app-select__btn-select {
      z-index: 99;
    }
  }

  .token-activity {
    .field {
      .token-name {
        min-height: 48px !important;
        padding: 14px 20px 0;
      }
    }
  }

  .box-upload {
    background-color: $white;
    border: 1px dashed $line;
    border-radius: 6px;
    color: $paragraph;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    margin-bottom: 13px;

    @include tablet {
      @include text-medium-regular;
    }
  }

  .type-upload-address {
    position: absolute;
    right: 0;
    top: -5px;
    color: $main !important;
  }

  .box-btn-create {
    justify-content: flex-end;
    margin-top: 20px;

    @include tablet {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}

.view-all {
  &:hover {
    .link {
      text-decoration: underline;
      color: $main;
    }
  }

  color: $main;
}

.box-filter {
  justify-content: flex-end;
  margin: 30px 40px 20px 0;

  .chakra-input__left-element {
    top: -8px;
  }

  .input-search {
    @include text-medium-regular;
    background-color: $white;
    padding: 4px 35px;
    height: 32px;
    margin-left: 8px;

    &::placeholder {
      color: $paragraph;
    }
  }

  &.activities-all {
    @include tablet {
      margin: 20px;
      justify-content: space-between;

      .input-search {
        margin-left: 0;
        padding: 4px 20px 4px 45px;
      }
    }
  }
}

.link-redirect {
  color: $paragraph2;

  &:hover {
    color: $main;
  }
}

.filter-table {
  position: relative;

  &:hover {
    cursor: pointer;
    color: $title;
  }

  &.active {
    color: $title;
  }

  &__box-search,
  &__options {
    background-color: $white;
    padding: 14px 10px;
    border-radius: 6px;
    position: absolute;
    width: 200px;
    top: 150%;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.04);

    .input-search {
      background-color: $white;
      @include text-small-regular;
      padding: 6px 10px;
      height: 32px;
      color: $title;

      &::placeholder {
        color: $line;
      }
    }
  }

  &__options {
    padding: 14px 0;
    text-transform: capitalize;
  }

  &__option {
    @include text-small-regular;
    padding: 8px 10px;

    &:hover,
    &.active {
      background-color: $main;
      color: $white;
    }
  }
}

.btn-create {
  @include text-medium-medium;
}

.box-invalid {
  margin-top: 26px !important;
  @include text-medium-regular;

  .link {
    color: $main !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.table-valid-address {
  background: rgba(238, 93, 80, 0.1);
  border: 1px solid $red;
  border-radius: 6px;

  .header-list {
    @include text-small-bold;
    padding: 12px 40px;
    color: $title;
    opacity: 0.3;
    text-transform: uppercase;
  }

  .header-list,
  .content-list {
    padding: 15px 40px;

    div:first-child {
      width: 60%;
      color: $title;
    }

    div:last-child {
      width: 40%;
      text-align: center;
    }
  }

  .content-list {
    @include text-medium-regular;
    border-bottom: 1px solid $red-100;

    &:last-child {
      // border-radius: 0 0 6px 6px;
      border: none;
    }
  }
}

.file-name {
  @include text-small-regular;
  color: $title;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: rgba(0, 2, 36, 0.05);
  border-radius: 6px;
  width: max-content;
  margin: 5px 0 5px 15px;

  .icon-clear {
    color: $paragraph;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
  }
}

.stripe-form {
  iframe {
    display: block !important;
  }
}

.des-status-activate {
  max-width: 450px;
  margin: auto;
}

.des-status-deactivate {
  max-width: 395px;
  margin: auto;
}
