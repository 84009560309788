@import '../color-variables';
@import '../variables';
@import '../mixins.scss';

.queries-page-header-buttons {
  margin-bottom: 20px;

  .query-button {
    margin-left: auto;
  }
}

$extraHeight: 18px;

.queries-page {
  margin-top: $extraHeight;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;

  &::before {
    content: '';
    display: block;
    height: $extraHeight;
    width: 50px;
    background: #fff;
    position: absolute;
    top: -$extraHeight;
    left: 0;
    z-index: 1;
    border-right: 1px solid rgba(0, 2, 36, 0.1);
  }

  &::after {
    content: '';
    display: block;
    height: $extraHeight;
    width: calc(100% - 50px);
    background: #fff;
    position: absolute;
    top: -$extraHeight;
    left: 50px;
    z-index: 1;
  }

  .expand {
    height: 100% !important;
    min-height: 400px;
  }

  .expand-chart {
    height: 744px !important;
    max-height: unset !important;

    .btn-expand-full {
      display: none !important;
    }
  }

  .add-chart-full {
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 20px;
    max-height: 500px;

    .tabs-header {
      padding: 7px 17px 7px 17px !important;
      border-bottom: unset !important;
    }
  }

  .header-empty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin-top: 8px;
    padding: 10px 16px 10px 14px;
    border-bottom: 1px solid rgba(0, 2, 36, 0.1);

    .item-add-chart {
      display: flex;
      align-items: center;
      gap: 7px;
      cursor: not-allowed;
    }

    .active-table {
      background: rgba(0, 2, 36, 0.05);
      border-radius: 4px;
      padding: 2px 11px;
    }

    svg {
      color: rgba(0, 2, 36, 0.5);
    }
  }

  .add-chart-empty {
    height: 375px;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
    justify-content: center;
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 20px;
  }

  .add-chart-loading-public {
    height: 375px;
    justify-content: center;
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 20px;
  }

  .add-chart-loading {
    height: 375px;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
    justify-content: center;
    border-radius: 6px;
  }

  .add-chart {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 20px;
    max-height: 500px;

    .tabs-container {
      margin: 8px 0px;
    }

    .btn-expand {
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    .btn-expand-full {
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .recharts-wrapper {
    .recharts-label-list .recharts-label {
      fill: $title;
    }

    .recharts-cartesian-axis .recharts-label {
      fill: $paragraph1;
    }
  }

  .ace_content {
    background-color: #fff;
  }

  .ace_gutter-layer {
    background: #fff;
    border-right: 1px solid rgba(0, 2, 36, 0.1);
    width: 50px !important;
  }

  .ace_gutter-active-line {
    background: #fff;
  }

  .ace_scroller {
    margin-left: 14px;
    overflow: unset;

    .ace_active-line,
    .ace_content {
      background: unset !important;
    }
  }

  .custom-editor {
    height: 96% !important;
    display: block;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: height linear 0.5s;

    .ace_print-margin {
      display: none;
    }

    .ace_text-layer {
      width: 100%;
    }
  }

  .control-editor {
    padding: 16px 12px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #2f3b58;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .custom-button {
      display: flex;
      flex-direction: row;
      position: relative;

      &>button {
        margin-right: 4px;
        border-radius: 8px;
        padding: 8px;
      }

      .btn-setting {
        margin-right: 4px;
        border-radius: 8px;

        &>button {
          padding: 0;
        }

        .menu-panel {
          position: absolute;
          width: 100%;
          top: -54px;
          left: 44px;
          z-index: 1000;
          border-radius: 6px;
          padding: 8px;
          min-width: 330px;

          &.theme-light {
            background-color: white;
            color: #1d1d20;
          }

          &.theme-dark {
            background-color: #1d1d20;
          }

          @include mobile {
            min-width: 260px;
          }

          &__item {
            cursor: pointer;
            padding: 6px 8px;

            &.theme-light {
              &:hover {
                background-color: #f3f5f7;
              }
            }

            &.theme-dark {
              &:hover {
                background-color: #232426;
              }
            }

            &--key {
              padding: 0 8px;
              margin-left: 2px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  &__right-side {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;

    @include desktop {
      width: 100%;
    }

    .btn-expand-public {
      top: 15px;
      width: fit-content;
      position: absolute;
      z-index: 5;
      right: 12px;

      &:hover {
        cursor: pointer;
      }
    }

    .editor-wrapper {
      height: 100%;
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
      width: 100%;
      border-radius: 6px;
      margin-bottom: 8px;
      position: relative;

      .ace_text-input {
        margin-left: 14px;
        color: var(--chakra-colors-bg-100);
        font: inherit !important;
        white-space: pre;
        letter-spacing: 0.2px;
      }

      .ace_gutter {
        overflow: unset;
        background: #fff;
        border-right: 1px solid rgba(0, 2, 36, 0.1);
        width: 50px !important;
      }

      .ace_editor {
        overflow: unset;
      }

      .ace_scrollbar-v {
        display: none;
      }
    }
    
    .visualization-wrapper {
      width: 100%;
      background-color: $white;
    }

    .empty-query {
      height: 100%;
      align-items: start;
      justify-content: space-between;
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      margin-top: 8px;

      &__main-header {
        padding: 3px 16px 10px 16px;
        border-bottom: 1px solid rgba(0, 2, 36, 0.1);
      }

      div {
        cursor: not-allowed;
      }
    }

    .empty-table {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .execution-error {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: $red-500;
      }
    }

    .visual-table {
      margin-top: 20px;

      .tr-list {
        td {
          border-bottom: none;
        }
      }
    }


    // used for react-split-pane
    .Pane {
      overflow: hidden;
    }

    .Pane2 {
      min-height: 0;
    }

    .Resizer {
      position: relative;
      flex: 0 0 auto;
      background: #0003;
      z-index: 1;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -moz-background-clip: padding;
      -webkit-background-clip: padding;
      background-clip: padding-box;

      &:after {
        content: "…";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 30px;
        height: 10px;
        background-color: white;
        border: 1px solid $line;
        border-radius: 20px;
        color: $bg1;
        text-align: center;
        line-height: 0px;
        font-size: 16px;
        z-index: 12;
        transform: translate(-50%, -50%);
      }

      &:hover {
        -webkit-transition: border-color 200ms ease;
        transition: border-color 200ms ease;
      }
      
      & .Resizer:hover:after {
        background: #fff;
      }

      &.horizontal {
        height: 11px;
        margin: -5px 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        cursor: row-resize;
        width: 100%;

        &:hover, &:active {
          &::after {
            background-color: $line;
            color: $white;
          }
        }
      }
    }
  }

  .schema-detail-title {
    cursor: default;
  }

  .block-describe {
    padding: 2px;
    cursor: pointer;

    &:hover {
      background-color: #ffffff1a;
    }
  }

  .visual-container {
    height: 100%;

    .tab-name-content {
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tabs-header {
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
      gap: 20px;
      align-items: baseline;
      padding: 10px 14px;
      border-bottom: 1px solid rgba(0, 2, 36, 0.1);
      background-color: $white;

      .btn-expand {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__visualization {
      min-height: 200px;
      height: fit-content;
      opacity: 1;
      transition: all linear 0.5s;
      position: relative;

      &__loading {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(244, 246, 249, 0.3);
        z-index: 999;
      }

      &--hidden {
        min-height: 0;
        height: 0;
        opacity: 0;
      }

      &--bar,
      &--line,
      &--scatter,
      &--area {
        width: 100% !important;
        height: 100% !important;
      }

      &__error {
        width: 100%;
        height: 100%;
      }

      &__chart {
        margin-top: 30px;
      }

      .main-chart {
        display: flex;
        width: 100%;
        overflow: hidden;

        .main-visualization {
          min-height: 150px;
          min-width: 72%;
          width: 72%;
          margin-top: 20px;
          padding: 0 15px;
          transition: all 0.15s linear;

          &--no-padding {
            padding: 0;
          }

          &--full-visual {
            width: 100% !important;
            min-width: 100%;
          }

          .main-table {
            height: calc(100% - 40px - 60px);
            max-height: calc(100% - 40px - 60px);
            overflow: auto;
            position: relative;
            background: var(--bg-copyright) no-repeat center;
            background-size: auto;

            &::-webkit-scrollbar {
              width: 6px;
              background-color: white;
              height: 6px;
              border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
              background: $line;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-corner {
              background: transparent;
            }
          }
        }

        .main-config {
          min-height: 120px;
          min-width: 28%;
          width: 28%;
          max-width: 28%;

          .body-config {
            border-left: 1px solid rgba(0, 2, 36, 0.1);
            overflow: auto;
            height: 100%;
          }

          .header-config {
            padding: 0px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close-config-icon {
              width: 8px;
              height: 8px;
              background: url('../../assets/icons/icon-default-work-place.png') -115px -11px;
              cursor: pointer;
            }

            .title-config {
              text-transform: uppercase;
              font-weight: 700;
              font-size: 12px;
              line-height: 26px;
            }
          }
        }
      }

      .data-length {
        @include text-small-regular;
        color: $paragraph;
        white-space: nowrap;

        @media (max-width: 992px) {
          padding: 0 14px;
        }
      }

      .app-pagination {
        margin: 0;
        padding: 14px 20px 16px;

        @media (max-width: 992px) {
          padding-right: 14px;
        }
      }

      .header-table {
        display: flex;
        margin: 0px 0px 10px 14px;

        .input-search {
          padding: 15px 15px 15px 30px;
          width: 280px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid $line;
          border-radius: 6px;
        }

        .chakra-input__left-element {
          top: 2px;
        }
      }
    }

    .app-tab {
      height: 100%;

      .tabs-container {
        border-radius: 4px;
        width: 90%;
        overflow: auto;
        flex-wrap: unset;
        height: 34px;
      }

      &__name-tab {
        margin-right: 0;
        border-radius: 4px;
        line-height: unset !important;
        padding: 2px 2px;

        span svg {
          color: rgba(0, 2, 36, 0.5);
        }

        &:hover {
          background-color: rgba(0, 2, 36, 0.05);
        }
      }

      &__name-tab[aria-selected='true'] {
        background-color: rgba(0, 2, 36, 0.05);
        text-decoration: none;

        span svg {
          color: #0060db;
        }

        .chakra-icon {
          color: rgba(0, 2, 36, 0.5);
        }
      }

      .table-value {
        min-width: 100%;
        height: fit-content !important;

        .table-nodata {
          width: 100%;
          position: absolute;
          margin-top: 50px;
        }

        td,
        th {
          text-align: center;
          font-size: 12px;
          box-shadow: none;
          box-shadow: inset 0px -1px 0px #e8eaed;
          padding: 0 120px 0 24px;
          white-space: nowrap;
        }

        .right-column {
          padding: 0 24px 0 120px;
        }

        .center-column {
          padding: 0 72px 0 72px;
        }

        thead {
          .resizer {
            display: none;
          }

          tr {
            th {
              font-size: 14px;
              line-height: 26px;
              font-weight: 700;
            }
          }
        }

        td {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .main-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        position: relative;

        .top-items {
          display: flex;
          justify-content: center;
          gap: 20px;
        }

        .bottom-items {
          display: flex;
          justify-content: center;
          gap: 20px;
        }

        .item-visual {
          display: flex;
          align-items: center;
          padding: 11px 16px;
          background: $bg;
          border: 1px solid $line;
          border-radius: 8px;
          gap: 10px;

          &:hover {
            background: linear-gradient(268.85deg, rgba(34, 108, 255, 0.12) 22.48%, rgba(16, 132, 255, 0.12) 83.59%);
            border: 1px solid #226cff;
            cursor: pointer;
          }
        }
      }

      .select-visual-type {
        width: 40%;

        ::-webkit-scrollbar {
          display: none;
        }

        .visual-type-content {
          .app-select {
            &__menu {
              max-height: max-content;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .queries-page {
    flex-direction: column;
  }

  .queries-page .visual-container .app-tab .main-item {
    .top-items,
    .bottom-items {
      flex-wrap: wrap;
      .item-visual {
        flex-direction: column;
        padding: 8px 16px;
        gap: 4px;
      }
    }
  }
}

@media (max-width: 576px) {
  .queries-page .visual-container .app-tab .main-item {
    flex-direction: row-reverse;
    padding-left: 20px !important;
    padding-right: 20px !important;
    align-items: flex-start;

    .top-items,
    .bottom-items {
      width: 100%;
      justify-content: flex-start;
    }

    .item-visual {
      width: 100%;
      height: 60px;
    }
  }
}