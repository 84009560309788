@import '../color-variables.scss';
@import '../mixins.scss';

.no-app {
  &__title {
    @include heading-4;
    margin-bottom: 12px;
    text-align: center;
  }

  &__description {
    @include text-medium-regular;
    margin-bottom: 40px;
    color: $paragraph1;
    text-align: center;
  }

  &__btn {
    @include text-medium-medium;
    padding: 12px 24px !important;
  }
}

.title-heading {
  @include heading-4;
  align-items: center;

  @include tablet {
    @include heading-6;

    &--center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.title-list-app {
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 20px 40px;

  .text-title {
    color: $title;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }

  @include tablet {
    padding: 24px 20px;

    .text-title {
      @include heading-8;
    }
  }
}

.list-app-hp {
  .number-app {
    @include text-medium-regular;
    margin-right: 20px;
    color: $main;

    span {
      color: $paragraph1;
    }
  }

  .btn-create {
    @include text-medium-medium;
  }
}

.list-table-wrap {
  padding: 0 0 40px 0 !important;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);
  border-radius: 18px;

  @include tablet {
    padding-bottom: 10px !important;
  }

  .tr-list {
    &:hover {
      background-color: $bg;
      cursor: pointer;
    }

    td {
      border-bottom: 1px solid $disable;
      color: $title;
      @include text-medium-regular;

      padding: 13px 15px;

      &:first-child {
        padding-left: 40px;
      }

      &:last-child {
        padding-right: 40px;
      }
    }

    &.show {
      background-color: rgba(244, 246, 249, 0.5);

      td {
        border-bottom: 0;
      }
    }

    .icon-down {
      display: flex;
      justify-content: flex-end;
    }

    .icon-down.open {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .dropdown-detail {
    background-color: $bg2;
  }

  .header-list {
    th {
      @include text-small-bold;
      border-bottom: 0;
      color: rgba(0, 2, 36, 0.3);
      padding: 13px 15px;
      font-family: 'DM Sans', sans-serif;
      z-index: 99;
      position: relative;

      .filter-table {
        opacity: 1;
      }

      &:first-child {
        padding-left: 40px;
      }

      &:last-child {
        padding-right: 40px;
      }
    }
  }

  .box-detail {
    background-color: rgba(244, 246, 249, 0.5);
    border-bottom: 0;
    border-bottom: 1px solid $disable;
    padding: 14px 40px 26px;

    @include tablet {
      padding: 0;
      border: 0;
      background-color: $white;
    }

    .label-detail {
      @include text-small-bold;
      color: rgba(0, 2, 36, 0.3);
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .content-detail {
      @include text-medium-regular;

      color: $title;
      background-color: $white;
      border: 1px solid $line;
      border-radius: 6px;
      overflow: hidden;
      height: 210px;
      max-width: 535px;

      @include tablet {
        margin-bottom: 15px;
        @include text-small-regular;
      }

      .react-json-view {
        padding: 20px;
        background-color: $white !important;
        color: $title;
        height: 210px;
        width: 100%;

        .pretty-json-container {
          padding-bottom: 20px;
        }
      }

      .content-output {
        padding: 20px;
      }
    }
  }
}

.user-graph,
.webhook {
  margin-top: 20px;

  @include tablet {
    margin-top: 20px;
  }
}

.billing-status {
  padding: 6px 10px;
  @include text-tiny-regular;
  border-radius: 14px;
  display: inline-block;

  &--inactive {
    background: rgba(234, 84, 85, 0.14);
    color: $red;
  }

  &--active {
    background: rgba(40, 199, 111, 0.14);
    color: $green;
  }

  &--waiting {
    background: rgba(255, 181, 71, 0.14);
    color: $yellow;
  }
}
.table-fixed{
  table-layout: fixed;
}

.list-card-mobile {
  margin: 0 20px;

  .card-mobile {
    background: $white;
    border: 1px solid $line;
    border-radius: 12px;
    padding: 16px 14px;
    margin-bottom: 16px;

    .info {
      @include text-small-regular;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      color: $paragraph2;

      &:last-child {
        margin-bottom: 0;
      }

      &>div:last-child {
        color: $title;
      }
    }

    .value {
      color: $title;
      text-align: right;
      max-width: 75%;
    }

    .name-mobile {
      @include text-medium-bold;
      color: $title;

      .box-current {
        @include text-tiny-regular;
        color: $blue;
        padding: 4px 10px;
        background: rgba(3, 202, 255, 0.1);
        border-radius: 14px;
      }
    }

    &.open {
      background-color: $white;
    }
  }
}

.legend {
  justify-content: center;

  div {
    cursor: pointer;
    @include text-medium-regular;
    color: $paragraph1;
    margin-right: 30px;
    position: relative;
    padding-left: 23px;

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;

      border-radius: 3px;
      top: 5px;
      left: 0;
    }

    &.message {
      &:before {
        background-color: $link;
      }
    }

    &.activities {
      &:before {
        background-color: $yellow;
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  @include tablet {
    margin-left: 20px;
    flex-direction: column;

    div {
      margin-bottom: 10px;
      @include text-small-regular;
    }
  }
}