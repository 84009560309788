@import '../color-variables.scss';
@import '../mixins.scss';

.modal {
  border-radius: 20px !important;
  padding: 8px;
  // min-width: 500px !important;

  @include tablet {
    margin: auto 16px !important;
  }

  &.modal-filter {
    @include tablet {
      height: 100vh;
      overflow-y: auto;
      border-radius: 0 !important;
      margin: 0 !important;
    }
  }

  &.full {
    min-height: 100vh;
    border-radius: 0 !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;

    .modal__title,
    .modal__icon {
      margin-top: 0;

      &.icon {
        margin-top: 20px;
      }
    }
  }

  &__btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
    border: 2px solid $line;
    border-radius: 48px;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include tablet {
      width: 30px;
      height: 30px;
    }
  }

  &__icon {
    margin: 50px auto 0;
  }

  &__title {
    position: relative;
    @include heading-4;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 20px;

    &.icon {
      margin-top: 28px;
    }

    @include tablet {
      @include heading-6;
    }

    .icon-back {
      cursor: pointer;
      position: absolute;
      top: 2px;
      left: 5px;
      width: 10px;
      height: 18px;
      transform: rotate(90deg);
    }
  }

  &__description,
  &__description-email {
    @include text-medium-regular;
    color: $paragraph1;
    margin-bottom: 7px;
    padding: 0 28px;
    text-align: center;
    white-space: pre-line;

    @include tablet {
      padding: 0;
    }

    .email {
      color: $paragraph1;
      font-weight: bold;
    }
  }

  &__title-send-mail {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    background: linear-gradient(180deg, rgba(5, 83, 195, 0.8) 52.6%, rgba(28, 112, 251, 0.8) 100%);
    background-clip: text;
    color: transparent;
    margin-bottom: 20px;
  }

  &__description-email {
    padding: 0;
  }

  &.modal-create-app {
    max-width: 530px !important;
  }

  &.modal-add-visualization {
    max-width: 500px !important;
  }

  .modal-footer {
    margin-top: 20px;

    &>button {
      width: 213px;
    }
  }
}

.infos {
  .info {
    @include text-medium-regular;
    background-color: rgba(0, 2, 36, 0.05);
    color: $paragraph;
    padding: 12px 16px;
    margin-bottom: 4px;

    div:first-child {
      width: 30%;
    }

    div:last-child {
      color: $title;
      width: 70%;
      text-align: right;
    }

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
}

.label-filter {
  @include text-small-bold;
  color: $title;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}

.list-option {
  div {
    color: $paragraph;
    @include text-medium-regular;

    .chakra-checkbox__label[data-checked] {
      color: $title;
    }
  }
}

.chakra-modal__body {
  @include tablet {
    padding: 0 10px !important;
  }
}

.box-wallet {
  width: 136px;
  padding: 20px 0;
  border: 1px solid $line;
  border-radius: 12px;
  color: $paragraph;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 7px;

  .name-wallet {
    margin-top: 10px;

    @include tablet {
      margin-left: 10px;
      margin-top: 0;
    }
  }

  &:last-child {
    .name-wallet {
      margin-top: 15px;

      @include tablet {
        margin-top: 0;
      }
    }
  }

  &:hover {
    border-color: $main;
  }

  svg {
    margin-bottom: 13px;
  }

  @include tablet {
    margin: 0 4px;
    flex-direction: row;
    width: 147px;
    padding: 16px 0;
    @include text-small-regular;

    svg {
      width: 20px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}

.info-payment {
  justify-content: space-between;
  padding: 12px 16px;
  background-color: $disable;
  margin-bottom: 4px;
  border-radius: 6px;

  .label {
    color: $paragraph;
    text-transform: capitalize;
  }
}

.main-modal-dashboard-details {
  padding-top: 40px;
  row-gap: 1rem !important;

  .text-widget-input {
    height: 150px;
    border-radius: 7px;
    background-color: #101530;
    min-width: 250px;
    border-color: #465065;
    max-width: 100%;
  }

  .title-markdown {
    width: 160px;
    color: #8d91a5;
  }

  .markdown {
    width: 100%;
  }

  .error-validate {
    color: $red-400;
    font-size: 14px;
  }

  // .chakra-stack {
  //   .label {
  //     color: $whiteAlpha-900;
  //   }
  // }
  .chakra-accordion__item {
    border: unset;

    span {
      -webkit-flex: unset;
      flex: unset;
    }

    button {
      padding: unset;
      display: flex;
      justify-content: space-between;
    }
  }

  .title-create-modal {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
  }

  .content-create-modal {
    margin-top: 10px;
    padding: 30px 16px;
    border-radius: 10px;
    width: 213px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f4f6f9;
    border: 1px solid #465065;
    cursor: pointer;

    svg {
      color: $paragraph;
    }

    &:hover {
      background: white;

      &:hover {
        border: 1px solid #1878ff;
      }

      svg {
        color: #1878ff;
      }
    }

    .title-content {
      font-size: 16px;
      font-weight: 700;
      margin: 12px 0 4px;
    }

    .desc-content {
      font-size: 12px;
      font-weight: 400;
      color: #8d91a5;
      text-align: center;
    }
  }

  .thumnail-create-modal {
    border: 1px dashed $line;
    border-radius: 6px;
    padding: 32px 0px;
    cursor: pointer;

    .desc {
      font-size: 14px;
      font-weight: 400;
      color: #465065;
      margin-top: 8px;
    }
  }

  .note {
    font-size: 14px;
    color: #8d91a5;
    font-weight: 400;
  }

  .table-main-markdown {
    .chakra-collapse {
      max-height: 250px;
      overflow-y: scroll !important;
      border-radius: 6px;
      margin-top: 10px;

      .chakra-accordion__panel {
        padding: 0;

        .main-markdown {
          padding: 12px 20px;

          a {
            text-decoration: underline;
            cursor: pointer;
          }

          p {
            font-size: 16px;
            font-weight: 400;
          }

          &:last-child {
            border: unset;
          }
        }
      }
    }
  }

  .main-queries {
    position: relative;
    height: 290px;
    margin-top: 20px;
    // color: $blackAlpha-900;
    font-size: 14px;
    border-radius: 2px;
    min-height: 290px;
    overflow: auto;

    .visualization-row {
      width: calc(100% - 20px);
      padding: 10px 0px 10px;

      svg {
        margin: 0 10px;
      }
    }

    .user-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 60%;
      // font-weight: bold;
      color: #8d91a5;
    }

    .visualization-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 40%;
      margin-right: 4px;
    }

    .no-data {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .btn-add-query {
      color: #1e1870;
      background: #e1e1f9;
      padding: 10px 20px;
      cursor: pointer;
    }

    .btn-added-query {
      color: $whiteAlpha-900;
      background: #1e1870;
      padding: 10px 20px;
      cursor: pointer;
    }
  }

  .group-action-query {
    width: 100%;

    button {
      font-size: 16px;
      width: 213px;
    }
  }
}

.modal-setting,
.modal-share-domain {
  &__title {
    text-align: center;
    margin-top: 40px;
    width: 100%;
    @include heading-5;
  }

  &__content {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .input-label {
    @include text-medium-regular;
    margin-bottom: 2px;
    color: $paragraph1;
  }

  .modal-footer {
    margin-top: 30px;
    gap: 10px;
    @include text-medium-medium;

    button {
      height: max-content;
    }
  }
}

.modal-share-domain {
  &__desc {
    margin-top: 28px;
    justify-content: center;
    gap: 26px;
    margin-bottom: 30px;

    .social-media {
      cursor: pointer;
    }
  }
}

.modal-delete {
  &__img {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__title {
    text-align: center;
    margin-top: 28px;
    width: 100%;
    @include heading-5;
  }

  &__content {
    margin-top: 8px;

    text-align: center;
    @include text-medium-regular;
    color: $paragraph1;
  }

  .modal-footer {
    margin-top: 30px;
    gap: 10px;
    @include text-medium-medium;

    button {
      height: max-content;
    }
  }
}

.modal-downgrade-plan {
  .chakra-modal__body {
    padding: 0 30px;
  }

  &__comparision {
    margin-bottom: 40px;

    &__plan {
      width: 275px;

      .plan-title {
        @include text-medium-regular;
        color: $paragraph;
        margin-bottom: 10px;
      }

      .plan-name {
        @include text-medium-bold;
        margin-bottom: 8px;
      }

      .plan-descriptions {
        &:not(&:last-child) {
          margin-bottom: 8px;
        }

        &__info {
          margin-left: 8px;

          &--cu {
            @include text-medium-bold;
          }
        }
      }
    }
  }

  &__period {
    margin-bottom: 24px;

    .period-title {
      @include text-medium-regular;
      color: $paragraph;
    }

    .period-time {
      @include text-medium-bold;
    }
  }

  .chakra-modal__footer {
    gap: 25px;
    justify-content: center;

    button {
      width: 222px;
    }
  }
}

wcm-modal {
  .wcm-overlay { // wallet connect modal
    &.wcm-active {
      z-index: 9999;
    }
  }
}