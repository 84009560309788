@import '../color-variables.scss';
@import '../mixins.scss';

.upload-abi {
  width: 100%;
  border-radius: 6px;

  .label-abi {
    @include text-medium-regular;
    color: $paragraph1;
  }

  .box-events {
    background-color: $bg;
    padding: 16px 24px;
    border-radius: 6px;

    .box-item {
      &:first-child {
        padding-bottom: 16px;
      }

      div {
        &:first-child {
          min-height: unset !important;
          max-height: unset !important;
        }
      }
    }
    .label-events {
      @include text-small-bold;
      text-transform: uppercase;
      color: $paragraph1;
      min-width: 90px;
      margin-right: 20px;
      opacity: 0.3;
    }
    .scroll-filter > div {
      max-height: unset !important;
    }

    label {
      display: flex;
      align-items: flex-start;
    }

    @include tablet {
      flex-direction: column;
      padding: 16px;

      div {
        margin-left: 0;
      }

      .label-events {
        margin-bottom: 10px;
      }
    }

    .abi-option {
      @include tablet {
        flex-direction: column;
      }
      .inputs {
        color: $paragraph;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 16rem;
      }
    }
  }

  .file-name {
    @include text-small-regular;
    color: $title;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background-color: rgba(0, 2, 36, 0.05);
    border-radius: 6px;
    margin: 0;

    .icon-clear {
      color: $paragraph;
      font-size: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .input-search {
    @include text-medium-regular;
    background-color: $white;
    padding: 4px 35px;
    height: 32px;
    margin-left: 8px;
    border-color: $line;

    &::placeholder {
      color: $paragraph;
    }

    @include tablet {
      margin-left: 0;
      width: 100% !important;
      padding: 4px 10px 4px 40px;
    }
  }

  .box-upload {
    background-color: $white;
    border: 1px dashed $line;
    border-radius: 6px;
    color: $paragraph;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    margin-bottom: 13px;
  }

  .download-template {
    @include text-medium-medium;
    color: $main !important;
    margin-bottom: 20px;
    cursor: pointer;

    svg {
      color: $main !important;
    }
  }

  .abi-detail {
    background-color: $white;
    padding: 22px 24px;
    border-radius: 6px;
    border: 1px solid $line;

    @include tablet {
      padding: 20px !important;
      margin-bottom: 20px;
      background-color: $bg;
      border: 0;

      .box-events {
        background-color: $white;

        .box-item {
          &:first-child {
            padding-bottom: 16px;
          }
        }
      }
    }

    .chakra-input__left-element {
      top: -8px;
    }
  }
}

.box-list-events {
  background-color: $bg;
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;

  @include tablet {
    padding: 16px;
  }
}
