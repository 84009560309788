@import '../color-variables.scss';
@import '../mixins.scss';

.table-value {
  table,
  .divTable {
    border: 1px solid lightgray;
    width: fit-content;
  }

  .tr {
    display: flex;
  }

  tr,
  .tr {
    width: fit-content;
    height: 30px;
  }

  th,
  .th,
  td,
  .td {
    // border-bottom: 1px solid $line;
    padding: 8px 2px;
  }

  th,
  .th {
    padding: 2px 4px;
    position: relative;
    font-weight: bold;
    text-align: center;
    height: 30px;
  }

  td,
  .td {
    height: 30px;
    .progressbar {
      display: flex;
      align-items: center;
      .visual-progressbar {
        margin-right: 5px;
        display: inline-block;
        position: relative;
        width: 80px;
        height: 8px;
        background: $disable;
        border-radius: 0.7rem;
        overflow: hidden;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: var(--myProgressBar);
          background: var(--myColor);
          border-radius: inherit;
        }
      }
    }
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: blue;
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      display: none;
    }
  }
}
