@import '../appStyles.scss';
@import '../color-variables.scss';
@import '../_mixins.scss';
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 32px 32px 0 42px;

  ul {
    display: flex;
    li {
      width: 40px;
      height: 40px;
      list-style-type: none;

      border-radius: 50%;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      @include flex-centered;
    }
    li.selected {
      background-color: $brand-400;
      border: 1px solid $brand-400;
      a {
        width: 100%;
        height: 100%;
        @include flex-centered;
      }
    }
    li.disabled {
      display: none;
    }
  }
}
.pagination-light {
  ul {
    li.selected {
      background-color: $brand-500;
      border: 1px solid $brand-500;
    }
  }
}

.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin: 32px 32px 10px 42px;
}

.load-more {
  display: flex;
  justify-content: center;
  margin-top: 25px;

  .btn-load-more {
    border-radius: 4px;
  }
}

table {
  z-index: 2;
}
