@import '../color-variables.scss';
@import '../mixins.scss';

.dashboard-container {
  border-radius: 6px;
  margin-top: -18px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
  }
  .main-pagination {
    position: fixed;
    bottom: 1%;
  }
  .react-resizable-handle {
    &::after {
      border-right: 2px solid rgba(255, 255, 255, 0.4);
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      right: 3px;
      bottom: 3px;
      width: 8px;
      height: 8px;
    }
  }
  .recharts-wrapper {
    .recharts-label-list .recharts-label {
      fill: $title;
    }

    .recharts-cartesian-axis .recharts-label {
      fill: $paragraph1;
    }
  }

  .main-counter {
    height: 100%;
  }

  .visual-container {
    &__visualization {
      height: 90%;

      .main-table {
        height: 100%;
        position: relative;
        background: var(--bg-copyright) no-repeat center;
        background-size: auto;
        overflow: auto;
      }

      .table-content {
        height: 100%;
        max-height: calc(100% - 80px);
        margin: 0 -17px;

        &::-webkit-scrollbar {
          width: 6px;
          background-color: white;
          height: 6px;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background: $line;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-corner {
          background: transparent;
        }

        .table-pagination {
          width: 100%;
          position: absolute;
          bottom: 0px;
          right: 0px;

          .data-length {
            @include text-medium-regular;
            color: $paragraph;
            white-space: nowrap;
            @media (max-width: 992px) {
              padding: 0 14px;
            }
            @media (max-width: 992px) {
              @include text-small-regular;
            }
          }

          .app-pagination {
            margin: unset !important;
            padding: 25px 25px 20px 10px;
            @media (max-width: 992px) {
              padding-right: 14px;
            }

            & > li {
              font-size: 14px;
              border-radius: 4px;
            }
          }
        }
        .header-table {
          top: 13px;
          right: 18px;
          position: absolute;

          .input-search {
            padding: 16px 16px 16px 30px;
            width: 160px;
            font-size: 12px;
            line-height: 16px;
            border: 1px solid $line;
          }
          .chakra-input__left-element {
            top: 4px;
          }
        }

        .editMode {
          right: 34px;

          .input-search {
            width: 150px;
          }
        }

        .table-value {
          th,
          td {
            padding: 0 120px 0 24px;
          }

          th {
            font-size: 12px;
            line-height: 26px;
            font-weight: 700;
          }

          td {
            font-size: 14px;
            line-height: 20px;
            box-shadow: inset 0px -1px 0px #e8eaed;
            white-space: nowrap;
            position: relative;
          }
          .table-nodata {
            width: 100%;
            position: absolute;
            margin-top: 50px;
          }
        }
      }

      .chart-content {
        height: 100%;
      }

      &__name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 53%;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }

      &__title {
        display: flex;
        margin-bottom: 20px;
        align-items: center;

        &__query-link {
          margin-left: 10px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 30%;
          color: #0060db;
          font-size: 14px;
          line-height: 26px;
        }
      }

      &__barchart {
        width: 96% !important;
        height: 92% !important;
      }

      &__linechart {
        width: 96% !important;
        height: 92% !important;
      }

      &--loading {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--loading,
      &--error,
      &--empty {
        height: calc(100% - 50px);
      }
    }

    .app-tab {
      .tabs-container {
        background-color: #111213;
        border-radius: 4px;
        margin-bottom: 20px;
      }

      &__name-tab {
        margin-right: 0;
        border-radius: 4px;
        line-height: unset !important;

        &__detail {
          gap: 4px;
        }

        &:hover {
          background-color: #ffffff1a;
        }
      }

      &__name-tab[aria-selected='true'] {
        background-color: #444444;
        text-decoration: none;
      }

      .table-value {
        min-width: 100%;
        height: fit-content !important;
        border: 1px solid #747474;

        td,
        th {
          text-align: center;
          font-size: 12px;
          box-shadow: none;
          border-bottom: 1px solid #747474;
          padding: 0 120px 0 24px;
        }
        thead {
          .resizer {
            display: none;
          }
          tr {
            background-color: #444444;
            th {
              font-size: 12px;

              font-weight: 400;
            }
          }
        }
      }

      .select-visual-type {
        width: 40%;
        ::-webkit-scrollbar {
          display: none;
        }
        .visual-type-content {
          .app-select {
            &__menu {
              max-height: max-content;
            }
          }
        }
      }
    }
  }
  .main-header-dashboard-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tag {
      background: $gray-200;
      color: $gray-600;
    }
    .btn-save {
      background: #1e1870;
      color: #fff;
    }

    .btn-cancel {
      background: #e1e1f9;
      color: #1e1870;
    }

    .btn-vote {
      background: #e1e1f9;
      color: #1e1870;
      min-width: 60px;
    }

    button {
      &:hover {
        background-color: #c5c5db;
      }
    }

    .dashboard-name {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .main-grid-layout {
    width: 100%;
    max-height: 100%;
    overflow-y: overlay;

    .react-grid-item {
      color: $title;
      overflow: hidden;
      cursor: grab;

      & > .react-resizable-handle.react-resizable-handle-se {
        bottom: 15px;
        right: 15px;
      }

      .visual-container__visualization__title__query-link {
        text-decoration: underline;
      }

      &.react-grid-placeholder {
        background-color: $line;
      }
    }

    .react-draggable-dragging {
      cursor: grabbing;
    }

    .box-layout {
      border-radius: 10px;
      position: relative;

      .box-chart {
        width: 100%;
        height: 100%;
        padding: 17px;
        position: relative;
        cursor: default;

        &--edit {
          cursor: grab;
        }

        .box-updated {
          position: absolute;
          z-index: 1000;
          left: 10px;
          bottom: 10px;
          svg {
            path {
              fill: rgba(0, 2, 36, 0.5);
            }
          }
        }
      }

      .box-text-widget {
        overflow: auto;
        height: 100%;

        & > ol,
        ul {
          margin-left: 18px;
        }

        a {
          text-decoration: underline;
          color: $main;
        }

        & > p {
          white-space: break-spaces;
        }

        & > hr {
          border-color: #000224;
        }

        &--edit {
          height: 80%;
          margin-top: 20px;
        }
      }

      .widget-buttons {
        position: absolute;
        cursor: pointer;
        top: 15px;
        right: 15px;
        height: 8%;

        svg {
          color: #8d91a5;
          transition: all 0.2s ease;

          &:hover {
            box-shadow: 0px 10px 40px rgba(125, 143, 179, 0.2);
            transform: translateY(-1px);
            color: $gray-400;
          }
        }
      }
    }
  }
}
