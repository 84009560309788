.icon-bitcoin {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -10px -10px;
}

.icon-polygon {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -50px -10px;
}

.icon-bsc {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -10px -50px;
}

.icon-solana {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -50px -50px;
}

.icon-ethereum {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -90px -10px;
}

.icon-aptos {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/aptos-icon.png') -10px -10px;
}

.icon-optimism {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -90px -50px;
}

.icon-arbitrum {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -10px -90px;
}

.icon-sui {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks.png') -90px -90px;
}

.icon-avax {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/avax.png');
}

.icon-bitcoin-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -10px -50px;
}

.icon-polygon-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -50px -50px;
}

.icon-bsc-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -10px -10px;
}

.icon-solana-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -90px -10px;
}

.icon-ethereum-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -10px -50px;
}

.icon-aptos-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png')-50px -90px;
}

.icon-optimism-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -90px -50px;
}

.icon-arbitrum-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -10px -90px;
}

.icon-sui-inactive {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_networks-inactive.png') -90px -90px;
}

.icon-eye-close {
  width: 21px;
  height: 15px;
  background: url('../assets/icons/icon_app.png') -92px -10px;
}

.icon-eye {
  width: 21px;
  height: 12px;
  background: url('../assets/icons/icon_app.png') -92px -45px;
}

.icon-trash {
  width: 24px;
  height: 24px;
  background: url('../assets/icons/icon_app3.png') -153px -106px;
}

.icon-google {
  width: 22px;
  height: 22px;
  background: url('../assets/icons/icon_app.png') -10px -10px;
}

.icon-arrow-left {
  width: 32px;
  height: 32px;
  background: url('../assets/icons/icon_app3.png') -153px -10px;
}

.icon-plus-circle {
  width: 16px;
  height: 16px;
  background: url('../assets/icons/icon_app.png') -10px -96px;
}

.icon-arrow-down {
  width: 10px;
  height: 5px;
  background: url('../assets/icons/icon_app.png') -46px -96px;
}

.icon-settings {
  width: 24px;
  height: 24px;
  background: url('../assets/icons/icon_app3.png') -153px -62px;
}

.icon-delete {
  width: 207px;
  height: 148px;
  background: url('../assets/icons/icon_modal.png') -260px -10px;
}

.icon-add-app {
  width: 168px;
  height: 150px;
  background: url('../assets/icons/icon_modal.png') -10px -205px;
}

.icon-sent-mail {
  width: 230px;
  height: 175px;
  background: url('../assets/icons/icon_modal.png') -10px -10px;
}

.icon-search {
  width: 14px;
  height: 14px;
  background: url('../assets/icons/icon_app2.png') -46px -112px;
}

.icon-checkbox {
  width: 24px;
  height: 24px;
  background: url('../assets/icons/icon_app2.png') -80px -10px;
}

.icon-checkbox-checked {
  width: 24px;
  height: 24px;
  background: url('../assets/icons/icon_app2.png') -10px -68px;
}

.icon-inactive {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon_app2.png') -124px -10px;
}

.icon-active {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon_app2.png') -124px -48px;
}

.icon-arrow-right {
  width: 12px;
  height: 12px;
  background: url('../assets/icons/icon_action.png') -10px -89px;
}

.icon-upload {
  width: 50px;
  height: 38px;
  background: url('../assets/icons/icon_app2.png') -10px -10px;
}

.icon-info {
  width: 18px;
  height: 20px;
  background: url('../assets/icons/icon_app2.png') -54px -68px;
}

.icon-filter {
  width: 12px;
  height: 8px;
  background: url('../assets/icons/icon_app2.png') -92px -68px;
}

.icon-download {
  width: 19px;
  height: 19px;
  background: url('../assets/icons/icon_action.png') -50px -10px;
}

.icon-link-top-active {
  width: 19px;
  height: 18px;
  background: url('../assets/icons/icon_action.png') -10px -51px;
}

.icon-filter-table {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon_action.png') -89px -10px;
}

.icon-link {
  width: 20px;
  height: 21px;
  background: url('../assets/icons/icon_action.png') -10px -10px;
}

.icon-link-top {
  width: 19px;
  height: 18px;
  background: url('../assets/icons/icon_action.png') -49px -51px;
}

.icon-retry {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon_action.png') -89px -48px;
}

.icon-download-table {
  width: 21px;
  height: 20px;
  background: url('../assets/icons/icon_mobile.png') -62px -10px;
}

.icon-filter-mobile {
  width: 30px;
  height: 30px;
  background: url('../assets/icons/icon-filter-mobile.png') -10px -10px;
}

.icon-minus {
  width: 16px;
  height: 2px;
  background: url('../assets/icons/icon_mobile_v2.png') -46px -60px;
}

.icon-plus {
  width: 16px;
  height: 16px;
  background: url('../assets/icons/icon_mobile_v2.png') -10px -60px;
}

.icon-menu-mobile {
  width: 20px;
  height: 18px;
  background: url('../assets/icons/icon_mobile_v2.png') -60px -10px;
}

.icon-close-menu {
  width: 30px;
  height: 30px;
  background: url('../assets/icons/icon_mobile_v2.png') -10px -10px;
}

.icon-busd {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_currency.png') -10px -50px;
}

.icon-usdt {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_currency.png') -50px -10px;
}

.icon-usdc {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_currency.png') -10px -10px;
}

.icon-tone {
  width: 24px;
  height: 24px;
  background: url('../assets/icons/icon_social_media.png') -54px -10px;
}

.icon-telegram {
  width: 24px;
  height: 25px;
  background: url('../assets/icons/icon_social_media.png') -10px -10px;
}

.icon-vector-right {
  width: 12px;
  height: 12px;
  background: url('../assets/icons/icon_social_media.png') -10px -55px;
}

.icon-no-data {
  width: 156px;
  height: 152px;
  background: url('../assets/icons/NoData.png') -10px -10px;
}

.icon-query-back-header {
  width: 11px;
  height: 18px;
  background: url('../assets/icons/icon_query.png') -110px -2px;
}

.icon-query-delete {
  width: 14px;
  height: 14px;
  background: url('../assets/icons/icon_query.png') -45px -84px;
}

.icon-query-fork {
  width: 14px;
  height: 16px;
  background: url('../assets/icons/icon_query.png') -86px -10px;
}

.icon-query-setting {
  width: 15px;
  height: 15px;
  background: url('../assets/icons/icon_query.png') -47px -48px;
}

.icon-query-share {
  width: 16px;
  height: 14px;
  background: url('../assets/icons/icon_query.png') -10px -84px;
}

.icon-query {
  width: 16px;
  height: 16px;
  background: url('../assets/icons/icon_query.png') -10px -92px;
}

.icon-query-expand {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_query.png') -48px -10px;
}

.icon-query-collapse {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_query.png') -10px -10px;
}

.icon-query-list {
  width: 25px;
  height: 25px;
  background: url('../assets/icons/icon_query.png') -138px -69px;
}

.icon-query-edit {
  width: 17px;
  height: 16px;
  background: url('../assets/icons/icon_query.png') -10px -48px;
}

.icon-run-query {
  width: 16px;
  height: 16px;
  background: url('../assets/icons/icon_query.png') -80px -83px;
}

.icon-expand-chart {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_query.png') -82px -46px;
}

.bg-icon_edit_dashboard {
  width: 12px;
  height: 12px;
  background: url('../assets/icons/icon_query.png') -149px -10px;
}

.bg-icon_success_dashboard {
  width: 12px;
  height: 12px;
  background: url('../assets/icons/icon_query.png') -149px -42px;
}

.icon-bar-chart-query {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_chart_query.png') -10px -90px;
}

.icon-counter-chart-query {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_chart_query.png') -50px -50px;
}

.icon-area-chart-query {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_chart_query.png') -10px -10px;
}

.icon-line-chart-query {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_chart_query.png') -50px -10px;
}

.icon-pie-chart-query {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_chart_query.png') -90px -10px;
}

.icon-scatter-chart-query {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_chart_query.png') -10px -50px;
}

.icon-table-chart-query {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon_chart_query.png') -90px -48px;
}

.bg-explore_active {
  width: 32px;
  height: 32px;
  background: url('../assets/icons/icon-active-work-place.png') -10px -10px;
}

.bg-work_place_active {
  width: 32px;
  height: 32px;
  background: url('../assets/icons/icon-active-work-place.png') -62px -10px;
}

.bg-query_active {
  width: 13px;
  height: 13px;
  background: url('../assets/icons/icon-active-work-place.png') -10px -62px;
}

.bg-dashboard_active {
  width: 12px;
  height: 12px;
  background: url('../assets/icons/icon-active-work-place.png') -43px -62px;
}

.bg-CloseBtnIcon {
  width: 10px;
  height: 10px;
  background: url('../assets/icons/icon-default-work-place.png') -114px -10px;
}

.bg-ExploreIcon {
  width: 32px;
  height: 32px;
  background: url('../assets/icons/icon-default-work-place.png') -10px -10px;
}

.bg-FolderIcon {
  width: 32px;
  height: 32px;
  background: url('../assets/icons/icon-default-work-place.png') -62px -10px;
}

.bg-LogoDashboardIcon {
  width: 12px;
  height: 12px;
  background: url('../assets/icons/icon-default-work-place.png') -43px -62px;
}

.bg-LogoQueryIcon {
  width: 13px;
  height: 13px;
  background: url('../assets/icons/icon-default-work-place.png') -10px -62px;
}

.bg-PlusIcon {
  width: 12px;
  height: 12px;
  background: url('../assets/icons/icon-default-work-place.png') -75px -62px;
}

.bg-chain_active {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon-explore-data.png') -10px -10px;
}

.bg-chain_default {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon-explore-data.png') -48px -10px;
}

.bg-arrow_icon {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon-explore-data.png') -10px -48px;
}

.bg-kebab_icon {
  width: 16px;
  height: 16px;
  background: url('../assets/icons/icon-explore-data.png') -48px -48px;
}

.bg-facebook_icon {
  width: 38px;
  height: 38px;
  background: url('../assets/icons/icon-share-domain-modal.png') -10px -10px;
}

.bg-linkedin_icon {
  width: 38px;
  height: 38px;
  background: url('../assets/icons/icon-share-domain-modal.png') -68px -10px;
}

.bg-telegram_icon {
  width: 38px;
  height: 38px;
  background: url('../assets/icons/icon-share-domain-modal.png') -10px -68px;
}

.bg-twitter_icon {
  width: 38px;
  height: 38px;
  background: url('../assets/icons/icon-share-domain-modal.png') -68px -68px;
}

.bg-copy_icon {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon-share-domain-modal.png') -126px -10px;
}

.bg-icon_bin {
  width: 187px;
  height: 128px;
  background: url('../assets/icons/icon-bin.png') -10px -10px;
}

.icon-widget-big-visualization {
  width: 28px;
  height: 28px;
  background: url('../assets/icons/icon-widget.png') -10px -10px;
}

.icon-widget-big-text {
  width: 28px;
  height: 28px;
  background: url('../assets/icons/icon-widget.png') -58px -10px;
}

.icon-widget-small-visualization {
  width: 16px;
  height: 16px;
  background: url('../assets/icons/icon-widget.png') -10px -58px;
}

.icon-widget-small-text {
  width: 16px;
  height: 16px;
  background: url('../assets/icons/icon-widget.png') -46px -58px;
}

.icon-explore-light {
  width: 32px;
  height: 32px;
  background: url('../assets/icons/icon-light.png') -10px -10px;
}

.icon-place-light {
  width: 32px;
  height: 32px;
  background: url('../assets/icons/icon-light.png') -62px -10px;
}

.icon-back-light {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/icon-light.png') -50px -55px;
}

.icon-close-light {
  width: 10px;
  height: 10px;
  background: url('../assets/icons/icon-light.png') -48px -106px;
}

.icon-plus-light {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon-light.png') -111px -10px;
}

.icon-close-white {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon-light.png') -10px -106px;
}

.icon-plus-white {
  width: 18px;
  height: 18px;
  background: url('../assets/icons/icon-light.png') -114px -48px;
}