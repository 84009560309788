@import '../color-variables.scss';
@import '../mixins';

.app-tag {
  background: #242b45;
  border-radius: 6px;
  color: white;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;

  &--sm {
    padding: 4px 6px;
    margin-right: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &--md {
    padding: 4px 10px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 24px;
  }

  &--clickable {
    cursor: pointer;
    border: 1px solid transparent;
  }

  &--selected {
    color: $title;
    border: 1px solid #1878ff;
  }

  &--closable {
    @include flex-items-center;
    justify-content: space-between;
  }

  &__close-button {
    margin-left: 4px;
  }
}
.app-tag-value {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
