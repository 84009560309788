@import '../mixins.scss';
@import '../color-variables';

.visual-container__visualization--bar {
  path.recharts-tooltip-cursor {
    display: none !important;
  }
}

.bar-chart,
.line-chart,
.pie-chart,
.scatter-chart,
.area-chart {
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 26px);
    left: 50%;
    width: 176px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    background: var(--bg-copyright) no-repeat center;
    background-size: contain;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
  }

  .custom-tooltip {
    background-color: $bg;
    border-radius: 6px;
    font-size: 14px;
    padding: 5px 10px;
    color: $paragraph1;
    // width: 200px;

    &__desc {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
      margin-top: 4px;
      .tooltip-value {
        font-weight: 600;
      }
    }
  }

  .custom-legend {
    padding-left: 20px;
    position: relative;
    display: flex;
    margin-right: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    // @include mobile {
    //   display: none;
    // }

    .line-chart-legend {
      left: 10px;
      width: 12px;
      height: 3px;
      position: absolute;
      padding: 0px !important;
      background-color: aliceblue;
      top: 50%;
      transform: translateY(-50%);
    }

    span:nth-child(1) {
      left: 10px;
      position: absolute;
      padding: 5px;
      background-color: aliceblue;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 1px;
    }

    span:nth-child(2) {
      // padding-right: 10px;
      padding-left: 9px;
      cursor: pointer;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: 16px;
    }

    .hide-legend {
      opacity: 0.5;
    }
  }

  & > .recharts-legend-wrapper {
    overflow: auto;
    max-height: 60%;
    max-width: 100%;

    & > div {
      margin-right: 10px;
    }
  }
  .recharts-cartesian-axis-line {
    display: none;
  }
  .recharts-text,
  .recharts-label {
    font-size: 12px;
  }
}

.pie-chart {
  .recharts-pie-sector {
    path {
      outline: none;
      border: none;
    }
  }
}