@import 'src/styles/Icons';
@import './color-variables.scss';
@import './mixins.scss';

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Italic.ttf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}

html {
  scroll-behavior: smooth;
}

iframe {
  display: none !important;
}

.text-error {
  font-weight: 400;
  color: #ee5d50;
  font-size: 14px;
}

.short-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 350px;
}

.chakra-checkbox__control {
  border: 1px solid $line !important;
  border-radius: 4px !important;
  height: 21px;
  width: 21px;
  background-color: inherit !important;

  &[data-checked] {
    background: $gradient !important;
    border: 0 !important;
  }

  &[data-indeterminate] {
    background: $gradient !important;
    border: 0 !important;
  }

  &[data-focus] {
    box-shadow: none !important;
  }

  svg {
    color: $white;
  }
}

.infinite-scroll-component.infinite-scroll {
  overflow: visible !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

.chakra-checkbox__label {
  @include text-medium-regular;
  font-size: 16px !important;
}
text.recharts-text.recharts-label {
  text-anchor: middle;
}

.link {
  color: $main !important;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: white;
  height: 4px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #c7d2e1;
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background: #c7d2e1;
}

// hide eye icon in password input Edge, IE
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recharts-tooltip-wrapper {
  outline: none !important;
  max-width: 300px;
}

.tooltip-app {
  color: $title !important;
  background: $white !important;
  padding: 10px 15px !important;
  border-radius: 6px !important;

  .chakra-tooltip__arrow {
    background: $white !important;
  }
}

.chakra-alert__icon {
  svg {
    color: $white;
  }
}

.grecaptcha-badge {
  display: none;
}

.input-password{
  padding-right: 42px !important;
}

// walletconnect modal
wcm-modal {
  position: relative;
  z-index: 9999;
}