@import '../color-variables.scss';
@import '../mixins.scss';

.account {
  .title-heading {
    @include heading-4;
    margin-bottom: 22px;

    @include tablet {
      @include heading-6;
    }
  }

  .box-account {
    width: calc(50% - 10px);

    @include tablet {
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .list-table-wrap {
    padding: 0;

    .text-title {
      @include heading-6;
      color: $title;
      padding: 30px 40px 22px 40px;

      @include tablet {
        @include heading-8;
        padding: 20px 20px 18px 20px;
      }
    }
  }

  .box-info-account {
    padding: 30px 33px;
    height: 100%;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.04);

    @include tablet {
      padding: 20px;
    }

    .info-item {
      padding-bottom: 10px;
      border-bottom: 1px solid $disable;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .title {
        @include text-medium-bold;
        color: $paragraph1;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .label {
        @include text-medium-regular;
        color: $paragraph2;
        width: 120px;

        @include tablet {
          width: 90px;
        }
      }

      .value {
        @include text-medium-regular;
        color: $title;
      }

      .btn-edit {
        color: $paragraph2;
        cursor: pointer;

        &:hover {
          color: $main;
        }
      }

      .name-plan {
        @include text-medium-bold;
        color: $title;
        margin-bottom: 8px;

        &.price {
          font-size: 48px;
          margin: 20px 0 30px 0;

          .month {
            font-size: 28px;
          }
        }
      }

      .detail-plan {
        @include text-medium-regular;
        color: $paragraph;
        div {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        svg {
          color: $green;
        }
      }
    }

    &.billing-info {
      justify-content: space-between;

      .info-item {
        padding-bottom: 0;
      }

      .name-next-plan {
        @include text-medium-bold;
        color: $title;
      }

      .time {
        @include text-medium-regular;
        color: $paragraph;
      }
    }

    &.payment-method {
      .info-item {
        border: 0;
        padding: 0;
        .label {
          width: 180px;
        }
      }

      .info-payment {
        color: $paragraph;
        margin-left: 4px;
      }
    }

    &.notification {
      .email {
        @include text-medium-regular;
        color: $paragraph2;
        margin-left: 32px;

        span {
          color: $paragraph2;
        }

        @include tablet {
          margin-left: 0;
        }

        &.active {
          color: $title;

          span {
            color: $paragraph2;
          }
        }
      }
    }

    &.accounts {
      .info-item {
        margin-bottom: 0;
        padding: 0;
        border-bottom: 0;
      }
    }
  }
}

.btn-copy {
  color: $paragraph;
  cursor: pointer;

  &:hover {
    color: $link;
  }
}

.billing {
  .title-billing {
    @include heading-5;
    align-items: center;
    margin-bottom: 22px;
    @include tablet {
      @include heading-7;
    }
  }

  .box-info-account {
    @include text-medium-regular;
    .label {
      color: $paragraph;
      width: 150px;
      @include tablet {
        margin-bottom: 10px;
      }
    }

    .value {
      color: $title;
    }
  }
}

.user-api {
  &__title {
    @include heading-6;

    @include tablet {
      @include heading-8;
    }

    color: $title;
    margin-bottom: 20px;
  }

  &__label {
    @include text-medium-regular;

    color: $paragraph1;
    margin-bottom: 4px;

    .link {
      color: $main !important;
    }
  }

  &__input {
    background-color: transparent !important;
    border: 1px solid $line !important;
    color: $title !important;
  }

  &__action {
    margin-right: 45px;
    align-items: center;

    svg {
      margin-left: 15px;
      color: $paragraph1;

      &:hover {
        color: $main;
      }
    }
  }
}

.allowist {
  .btn-add {
    @include text-medium-medium;

    cursor: pointer;
    align-items: center;
  }
}
