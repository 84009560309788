@import '../color-variables.scss';
@import '../mixins.scss';

.main-counter {
  position: relative;
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    max-width: 80%;

    &__value {
      font-size: 28px;
      line-height: 38px;
      font-weight: 700;
    }

    &__label {
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
      color: $paragraph2;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 90px;
    height: 100px;
    max-width: 100%;
    max-height: calc(100% - 52px);
    left: 50%;
    top: calc(50% - 26px);
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
  }
}

.config-counter {
  font-size: 14px;
  display: grid;
  grid-gap: 10px;

  .divider-bottom {
    border-bottom: 1px solid $line;
    padding: 4px;
  }

  .z-100 {
    z-index: 100;
  }

  .btn-add {
    background: $gray-200;
    color: $blackAlpha-800;
    font-size: 14px;
    &:hover {
      background: $gray-400;
    }
  }

  .first-box-table {
    padding: 16px 12px 4px 12px !important;
  }

  .box-table {
    min-height: 100%;
    padding: 12px 12px 4px 12px;

    .main-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0px 10px;
      color: $title;

      .chakra-checkbox__label {
        font-size: 14px !important;
      }

      .label-toggle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .main-checkbox {
      padding: 5px 0px 10px;
      color: $title;

      .chakra-checkbox__label {
        font-size: 14px !important;
      }
    }

    .box-table-children {
      display: grid;
      color: $title;
      padding: 5px 0;
      gap: 4px;

      .label-input {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .select-table {
        .app-select__btn-select {
          padding: 0px 17px;
          font-size: 14px;
          border: 1px solid $line;
        }

        .app-select__menu-item {
          padding: 8px 16px;
        }
      }

      .input-table {
        font-size: 13px;
        border-color: transparent;
        border-radius: 5px;
        border: 1px solid $line;
        padding: 18px;
        height: 0;
      }
    }
  }
}
