@import '../color-variables.scss';
@import '../mixins.scss';

.field {
  .label {
    @include text-medium-regular;
    color: $paragraph1;
    min-height: 24px;
  }
}
