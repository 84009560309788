@import '../color-variables.scss';
@import '../mixins.scss';

.main-layout {
  font-size: 14px;
  color: $blackAlpha-800;
  display: grid;
  background: #1a203b;

  .z-100 {
    z-index: 100;
  }
  .btn-add {
    background: $gray-200;
    color: $blackAlpha-800;
    font-size: 14px;
  }

  .first-box-table {
    min-height: unset !important;
  }

  .divider-bottom {
    border-bottom: 1px solid $line;
    padding: 4px;
  }

  .select-column {
    padding: 2px 12px 2px 12px;

    .app-select__btn-select {
      padding: 0px 2px;
      font-size: 14px;
      border: unset;
      font-weight: 700;
    }
  }

  .box-table {
    padding: 6px 12px;

    .main-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0px 10px;
      color: $title;

      .chakra-checkbox__label {
        font-size: 14px !important;
      }

      .label-toggle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &--disabled {
          color: #61687d;
        }
      }
    }

    .box-table-children {
      display: grid;
      color: $title;
      margin-bottom: 12px;

      .label-input {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $paragraph;
        margin-bottom: 4px;
      }

      .select-table {
        .app-select__btn-select {
          padding: 0px 17px;
          font-size: 14px;
        }

        .app-select__menu-item {
          padding: 8px 16px;
        }
      }

      .input-table {
        font-size: 13px;
        border-color: transparent;
        border-radius: 5px;
        border: 1px solid $line;
        padding: 18px;
        height: 0;
      }
    }
  }
}
